export const state = () => ({
	solutions: [],
	categories: []
})

export const actions = {
	async fetchAllSolutions({ state, commit }) {
		let solutions = await this.$axios.$get('https://api.storyblok.com/v2/cdn/stories?starts_with=solutions/&is_startpage=0&sort_by=position:asc&per_page=100&token=' + process.env.storyBlokAPIKey + '&version=' + process.env.storyBlokAPIVersion, { useCache: false })

		if (solutions && solutions.stories) commit('setAllSolutions', solutions.stories)
	},
	async fetchSolutionCategories({ state, commit }) {
		let categories = await this.$axios.$get('https://api.storyblok.com/v2/cdn/datasource_entries?datasource=solution-categories&token=' + process.env.storyBlokAPIKey, { useCache: false })

		commit('setSolutionCategories', categories.datasource_entries)
	}
}

export const getters = {
	getAllSolutions: state => {
		return state.solutions
	},
	getSolutionsByCategory: state => category => {
		const matches = []
		// find all solutions that are tagged with the given vertical/category
		if (state.solutions.length) {
			state.solutions.forEach(solution => {
				if (solution.content && solution.content.data) {
					solution.content.data.forEach(component => {
						if (component.component === 'solution_data') {
							if (component.verticals && component.verticals.includes(category)) {
								matches.push(solution)
							}
						}
					})
				}
			})
		}
		return matches
	},
	getCategoryNameByValue: state => value => {
		if (state.categories.length) return state.categories.find(item => item.value == value).name
	},
	getSolutionByUUID: state => uuid => {
		if (state.solutions.length) return state.solutions.find(item => item.uuid == uuid)
	},
	getBookmarkedSolutions: (state, getters, rootState, rootGetters) => {
		const bookmarks = rootGetters['bookmarks/getAllBookmarks']
		const results = []

		bookmarks.forEach(bookmark => {
			if (bookmark.startsWith('solutions/')) {
				state.solutions.forEach(solution => {
					if (solution.full_slug === bookmark) results.push(solution)
				})
			}
		})

		return results
	}
}

export const mutations = {
	setAllSolutions(state, solutions) {
		state.solutions = solutions
	},
	setSolutionCategories(state, categories) {
		state.categories = categories
	}
}
