//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { func } from 'assets/func.js'

Vue.prototype.$func = func

export default {
	components: {
		GlobalModal: () => import("@/components/GlobalModal.vue"),
		Page404: () => import("@/components/Page404.vue"),
		PageError: () => import("@/components/PageError.vue"),
	},
	head() {
		return {
			title: this.error.statusCode === 404 ? '404': 'Error'
		}
	},
	props: ['error'],
	mounted() {}
}
