import { render, staticRenderFns } from "./Page404.vue?vue&type=template&id=38579dc8&"
import script from "./Page404.vue?vue&type=script&lang=js&"
export * from "./Page404.vue?vue&type=script&lang=js&"
import style0 from "./Page404.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports