//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		showLoginModal() {
			// save intent object
			this.$intent.set('download', this.$route.path, { file: this.blok.file.filename })

			this.$bus.$emit('openModal')
		}
	},
	mounted() {},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		},
		isFile() {
			if (this.blok.file && this.blok.file.filename) return true
		},
		download() {
			if (this.blok.link.cached_url.includes('.pdf') || this.blok.link.cached_url.includes('.zip')) {
				return 'icon-download'
			}
		}
	}
}
