//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		Cards: () => import('@/components/Cards.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		solutions() {
			if (this.blok.category)
				return this.$store.getters['solutions/getSolutionsByCategory'](this.blok.category)
			else
				return this.$store.getters['solutions/getAllSolutions']
		},
		cardData() {
			const data = {
				type: 'solutions',
				autocrop: ['oneone'],
				dataSize: 'col3',
				columns: ['col4']
			}
			data.uuids = Array.from(this.solutions, item => item.uuid)
			return data
		}
	}
}
