//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data() {
		return {}
	},
	components: {
		EmailSignUp: () => import('@/components/EmailSignUp.vue'),
		Social: () => import('@/components/Social.vue')
	},
	computed: {
		footerData() {
			return this.$store.getters['globalFooter/getFooterData']
		}
	},
	mounted() {
		this.$store.dispatch('globalFooter/fetchAllFooterData');
	},
	methods: {
		//Because v-for takes priority of v-if
		getSignUpContent() {
			if(this.footerData.story && this.footerData.story.content){
				return this.footerData.story.content.signup;
			}
			return {};
		},
		getLinkSet() {
			if(this.footerData.story && this.footerData.story.content){
				return this.footerData.story.content.linkset_1;
			}
			return {};
		},
		getSocial() {
			if(this.footerData.story && this.footerData.story.content){
				return this.footerData.story.content.social;
			}
			return {};
		},
		contactForm: function() {
			this.showNav = false
			this.$bus.$emit('openContactModal')
		}
	},
	props: ['blok']
}
