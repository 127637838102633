//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		TabLinkBar: () => import("@/components/RollingOffice/TabLinkBar.vue"),
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		showVideoModal: function(url) {
			this.$bus.$emit('openVideoModal', { type: 'video', url: url })
		},
		snapToTop() {
			if(this.blok && this.blok.snap_to_top) {
				return ' snap';
			}
		}
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		text() {
			if (this.blok.Text) {
				return this.$storyapi.richTextResolver.render(this.blok.Text)
			}
		}
	}
}
