//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		productApplications() {
			return this.$store.getters['products/getProductApps']
		}
	}
}
