import Vue from 'vue'

export const rte = {
	install(Vue, options) {
		Vue.prototype.$rte = new Vue()

		const MySchema = require('storyblok-js-client/dist/schema')

		MySchema.nodes.heading = function(node) {
			let attrs = {}

			if (node.processed) {
				attrs = node.attrsCopy
			}

			if (node.content && node.content.length === 1 && node.content[0].marks && node.content[0].marks.length === 1 && node.content[0].marks[0].type === 'styled') {
				attrs = node.content[0].marks[0].attrs
				node.attrsCopy = attrs
				node.processed = true
				delete node.content[0].marks
			}

			return {
				tag: [
					{
						tag: `h${node.attrs.level}`,
						attrs: attrs
					}
				]
			}
		}
		MySchema.nodes.paragraph = function(node) {
			let attrs = {}

			if (node.processed) {
				attrs = node.attrsCopy
			}

			if (node.content && node.content.length === 1 && node.content[0].marks && node.content[0].marks.length === 1 && node.content[0].marks[0].type === 'styled') {
				attrs = node.content[0].marks[0].attrs
				node.attrsCopy = attrs
				node.processed = true
				delete node.content[0].marks
			}

			return {
				tag: [
					{
						tag: 'p',
						attrs: attrs
					}
				]
			}
		}
		MySchema.nodes.list_item = function(node) {
			let attrs = {}

			if (node.content && node.content.length === 1 && node.content[0].type === 'paragraph') {
				let content = node.content[0].content
				node.content = content
			}

			return {
				tag: 'li'
			}
		}
	}
}
Vue.use(rte)
