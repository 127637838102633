//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		SliderItem: () => import('@/components/SliderItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
