//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		ListIcon: () => import('~/components/ListIcon.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		rte() {
			if (this.blok.rte) {
				return this.$storyapi.richTextResolver.render(this.blok.rte)
			}
		}
	}
}
