export const state = () => ({
	navData: []
})

export const actions = {
	async fetchAllNavData({ state, commit }) {
		let navData = await this.$axios.$get('https://api.storyblok.com/v1/cdn/stories/global-header?token=' + process.env.storyBlokAPIKey + '&version=' + process.env.storyBlokAPIVersion + '&resolve_links=url', { useCache: false })

		commit('setNavData', navData)
	}
}

export const getters = {
	getNavData: state => {
		return state.navData
	}
}

export const mutations = {
	setNavData(state, navData) {
		state.navData = navData
	}
}
