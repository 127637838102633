export const state = () => ({
	products: [],
	applications: []
})

export const actions = {
	async fetchAllProducts({ state, commit }) {
		let products = await this.$axios.$get('https://api.storyblok.com/v1/cdn/stories?starts_with=products/&sort_by=position:asc&token=' + process.env.storyBlokAPIKey + '&version=' + process.env.storyBlokAPIVersion, { useCache: false })
		products.stories = products.stories.filter(product => !['products','design-resources','reconfigure'].includes(product.slug))
		commit('setProducts', products.stories)
	},
	async fetchProductApplications({ state, commit }) {
		let productApplications = await this.$axios.$get('https://api.storyblok.com/v1/cdn/datasource_entries?datasource=product-applications&token=' + process.env.storyBlokAPIKey, { useCache: false })

		commit('setProductApplications', productApplications.datasource_entries)
	}
}

export const getters = {
	getProductApplications: state => {
		return state.applications
	},
	getProductApplicationByValue: state => value => {
		if (state.applications.length) {
			const index = state.applications.findIndex(item => item.value == value)
			const item = state.applications.find(item => item.value == value)
			if (index >= 0 && item) {
				item.index = index
				return item
			}
		}
	},
	getAllProducts: state => {
		return state.products
	},
	getBookmarkedProducts: (state, getters, rootState, rootGetters) => {
		const bookmarks = rootGetters['bookmarks/getAllBookmarks']
		const results = []

		bookmarks.forEach(bookmark => {
			if (bookmark.startsWith('products/')) {
				state.products.forEach(product => {
					if (product.full_slug === bookmark) results.push(product)
				})
			}
		})

		return results
	},
	getProductByUUID: state => uuid => {
		if (state.products.length) return state.products.find(item => item.uuid == uuid)
	}
}

export const mutations = {
	setProducts(state, products) {
		state.products = products
	},
	setProductApplications(state, productApplications) {
		state.applications = productApplications
	}
}
