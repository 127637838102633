//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		contactForm: function() {
			this.showNav = false
			this.$bus.$emit('openContactModal')
		}
	},
    computed: {
        cssVars() {
            return {
                '--margin-top': (this.blok.margin_top != '' ? this.blok.margin_top : '10')+'px',
                '--margin-bottom': (this.blok.margin_bottom != '' ? this.blok.margin_bottom : '10')+'px'
            }
        }
    }

}
