//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok', 'selected'],
	methods: {
		isSelected() {
			return this.selected;
		},
		style() {
			if(this.blok && this.blok.image.filename) {
				return 'background-image:url(' + this.blok.image.filename + ')';
			}
		}
	},
	mounted() {},
	computed: {}
}
