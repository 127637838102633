//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components: {
    },
    data() {
        return {}
    },
    props: ['blok'],
    methods: {
       
    },
    mounted() {
        console.debug(this.blok)
    },
    computed: {
        
    }
}
