//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { func } from 'assets/func.js'

Vue.prototype.$func = func

export default {
	components: {
		GlobalHeader: () => import('@/components/GlobalHeader.vue'),
		GlobalFooter: () => import('@/components/GlobalFooter.vue'),
		GlobalModal: () => import('@/components/GlobalModal.vue'),
		GDPR: () => import('@/components/GDPR.vue')
	},
	props: [],
	data() {
		return {}
	},
	mounted() {}
}
