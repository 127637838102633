//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    export default {
        components: {
        },
        data() {
            return {}
        },
        props: ['blok'],
        methods: {
            background() {
                if(this.blok.background) {
                    return 'background:' + this.blok.background.color;
                }
            },
            textColor() {
                return 'color: ' + this.blok.text_color;
            },
            bgimage() {
                if (this.blok.image) {
                    return 'background-image:url(' + this.blok.image.filename + ')';
                }
            }
            
        },
        mounted() {
            console.debug(this.blok);
            let para = document.createElement('script');
            para.setAttribute('src', 'https://cdn.jsdelivr.net/npm/simple-parallax-js@5.5.1/dist/simpleParallax.min.js');
            document.head.appendChild(para);
            para.onload = function() {
                var image = document.querySelectorAll('img.slideimage');
                var image2 = document.querySelectorAll('img.bs-image__image');
                new simpleParallax(image, {delay: 0.1, transition: 'cubic-bezier(.49,.73,.64,.66)', scale: 1.1});
                new simpleParallax(image2, {delay: 0.1, transition: 'cubic-bezier(.49,.73,.64,.66)', scale: 1.2});
		    };
        },
        computed: {
            description() {
                if (this.blok.description) {
                    return this.$storyapi.richTextResolver.render(this.blok.description)
                }
            },
            note() {
                if (this.blok.note) {
                    return this.$storyapi.richTextResolver.render(this.blok.note)
                }
            }
        }
    }
    