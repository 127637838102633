import { render, staticRenderFns } from "./Course.vue?vue&type=template&id=354cfd5d&"
import script from "./Course.vue?vue&type=script&lang=js&"
export * from "./Course.vue?vue&type=script&lang=js&"
import style0 from "./Course.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/home/webadmin/sites/bnind.com/bnfe/releases/20231207T215914/components/Picture.vue').default})
