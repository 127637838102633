//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok', 'short'],
	methods: {
		image(imageSize, fileType = null, isHighResolution = false) {
			const highResolutionScale = 1.5
			let width, height, image

			if (imageSize === 'small') {
				image = this.blok.src_small
				if (this.short) {
					width = 768
					height = 400
				} else {
					width = 768
					height = 605
				}
			} else if (imageSize === 'large') {
				image = this.blok.src_large
				if (this.short) {
					width = 1440
					height = 500
				} else {
					width = 1440
					height = 960
				}
			}

			if (image) {
				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(image.filename, {
					size: width + 'x' + height,
					focus: image.focus,
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {
	},
	computed: {} 
}
