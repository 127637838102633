//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components: {
    },
    data() {
        return {}
    },
    props: ['blok'],
    methods: {
        styles() {
            let bgColor, textColor;
            if (this.blok && this.blok.background) {
                bgColor = 'background:' + this.blok.background.color + ';';
            }
            if (this.blok && this.blok.text_color) {
                textColor = 'color:' + this.blok.text_color + ';';
            }

            const styles = bgColor + textColor;

            return styles;
        }
    },
    mounted() {
        console.debug(this.blok)
    },
    computed: {
        text() {
            if (this.blok.text) {
                return this.$storyapi.richTextResolver.render(this.blok.text).replace(/\|/g, "<br />");
            }
        },
    }
}
