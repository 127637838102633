//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Portal } from '@linusborg/vue-simple-portal'
let animatingZIndex = 0

export default {
	name: 'VueModal',
	components: {
		Portal
	},
	model: {
		prop: 'basedOn',
		event: 'close'
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		baseZindex: {
			type: Number,
			default: 100
		},
		bgClass: {
			type: String,
			default: ''
		},
		wrapperClass: {
			type: String,
			default: ''
		},
		modalClass: {
			type: String,
			default: ''
		},
		modalStyle: {
			type: Object,
			default: () => ({})
		},
		inClass: {
			type: String,
			default: 'vm-fadeIn'
		},
		outClass: {
			type: String,
			default: 'vm-fadeOut'
		},
		bgInClass: {
			type: String,
			default: 'vm-fadeIn'
		},
		bgOutClass: {
			type: String,
			default: 'vm-fadeOut'
		},
		appendTo: {
			type: String,
			default: 'body'
		},
		live: {
			type: Boolean,
			default: false
		},
		enableClose: {
			type: Boolean,
			default: true
		},
		basedOn: {
			type: Boolean,
			default: false
		}
	},
	data: function() {
		return {
			zIndex: 0,
			id: null,
			show: false,
			mount: false,
			elToFocus: null
		}
	},
	created() {
		if (this.live) {
			this.mount = true
		}
	},
	mounted() {
		this.id = 'vm-' + this._uid
		this.$watch(
			'basedOn',
			function(newVal) {
				if (newVal) {
					this.mount = true
					this.$nextTick(() => {
						this.show = true
					})
				} else {
					this.show = false
				}
			},
			{
				immediate: true
			}
		)
		// Close from link in modal content
		this.$bus.$on('closeModal', () => {
			this.$emit('close', false)
		})
	},
	beforeDestroy() {
		this.elToFocus = null
	},
	methods: {
		close() {
			if (this.enableClose === true) {
				this.$emit('close', false)
				// Play All Video Clips
				this.$bus.$emit('playVideoClip')
			}
		},
		clickOutside(e) {
			if (e.target === this.$refs['vm-wrapper']) {
				this.close()
			}
		},
		keydown(e) {
			if (e.which === 27) {
				this.close()
			}
			if (e.which === 9) {
				// Get only visible elements
				let all = [].slice.call(this.$refs['vm-wrapper'].querySelectorAll('input, select, textarea, button, a'))
				all = all.filter(function(el) {
					return !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length)
				})
				if (e.shiftKey) {
					if (e.target === all[0] || e.target === this.$refs['vm-wrapper']) {
						e.preventDefault()
						all[all.length - 1].focus()
					}
				} else {
					if (e.target === all[all.length - 1]) {
						e.preventDefault()
						all[0].focus()
					}
				}
			}
		},
		getTopZindex() {
			let toret = 0
			const all = document.querySelectorAll('.vm-wrapper')
			for (let i = 0; i < all.length; i++) {
				if (all[i].display === 'none') {
					continue
				}
				toret = parseInt(all[i].style.zIndex) > toret ? parseInt(all[i].style.zIndex) : toret
			}
			return toret
		},
		modalsVisible() {
			const all = document.querySelectorAll('.vm-wrapper')
			// We cannot return false unless we make sure that there are not any modals visible
			let foundVisible = 0
			for (let i = 0; i < all.length; i++) {
				if (all[i].display === 'none') {
					continue
				}
				if (parseInt(all[i].style.zIndex) > 0) {
					foundVisible++
				}
			}
			return foundVisible
		},
		handleFocus(wrapper) {
			const autofocus = wrapper.querySelector('[autofocus]')
			if (autofocus) {
				autofocus.focus()
			} else {
				const focusable = wrapper.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')
				focusable.length ? focusable[0].focus() : wrapper.focus()
			}
		},
		beforeOpen() {
			// console.log('beforeOpen');
			this.elToFocus = document.activeElement
			const lastZindex = this.getTopZindex()
			if (animatingZIndex) {
				this.zIndex = animatingZIndex + 2
			} else {
				this.zIndex = lastZindex === 0 ? this.baseZindex : lastZindex + 2
			}
			animatingZIndex = this.zIndex
			this.$emit('before-open')
		},
		opening() {
			// console.log('opening');
			this.$emit('opening')
			// Stop All Video Clips
			this.$bus.$emit('stopVideoClip')
		},
		afterOpen() {
			// console.log('afterOpen');
			this.handleFocus(this.$refs['vm-wrapper'])
			this.$emit('after-open')
		},
		beforeClose() {
			// console.log('beforeClose');
			this.$emit('before-close')
		},
		closing() {
			// console.log('closing');
			this.$emit('closing')
		},
		afterClose() {
			// console.log('afterClose');
			this.zIndex = 0
			if (!this.live) {
				this.mount = false
			}
			this.$nextTick(() => {
				window.requestAnimationFrame(() => {
					const lastZindex = this.getTopZindex()
					if (lastZindex > 0) {
						const all = document.querySelectorAll('.vm-wrapper')
						for (let i = 0; i < all.length; i++) {
							const wrapper = all[i]
							if (wrapper.display === 'none') {
								continue
							}
							if (parseInt(wrapper.style.zIndex) === lastZindex) {
								if (wrapper.contains(this.elToFocus)) {
									this.elToFocus.focus()
								} else {
									// console.log(wrapper);
									this.handleFocus(wrapper)
								}
								break
							}
						}
					} else {
						if (document.body.contains(this.elToFocus)) {
							this.elToFocus.focus()
						}
					}
					animatingZIndex = 0
					this.$emit('after-close')
				})
			})
		}
	}
}
