//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components: {
    },
    data() {
        return {}
    },
    props: ['blok'],
    methods: {
        image(fileType = null, isHighResolution = false) {
            const highResolutionScale = 1
            let width, height

            if (this.blok && this.blok.image && this.blok.image.filename) {
                // default size
                width = 720
                height = 680

                // scale up for highres image
                if (isHighResolution) {
                    height *= highResolutionScale
                    width *= highResolutionScale
                }

                // crop/scale the image
                const processedImage = this.$func.imageService(this.blok.image.filename, {
                    size: width + 'x' + height,
                    focus: this.blok.image.focus,
                    fileType: fileType
                })
                return processedImage
            }
        },
        styles() {
            let bgColor, textColor;
            if (this.blok && this.blok.text_background) {
                bgColor = 'background:' + this.blok.text_background.color + ';';
            }
            if (this.blok && this.blok.text_color) {
                textColor = 'color:' + this.blok.text_color + ';';
            }

            const styles = bgColor + textColor;

            return styles;
        },
        smallerPadding() {
            if (this.blok && this.blok.smaller_padding) {
                return ' smaller-padding';
            }
        }
    },
    mounted() {
        console.debug(this.blok)
    },
    computed: {
        text() {
            if (this.blok.text) {
                return this.$storyapi.richTextResolver.render(this.blok.text)
            }
        },
    }
}
