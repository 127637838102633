//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	mounted() {
		let slug = this.blok.iframe_src.cached_url
		if(slug.includes('SORBETTI')){
			let lib = document.createElement('script')
			lib.setAttribute('src', 'https://portal.combeenation.com/plugin/bnindustries/SORBETTI')
			document.head.appendChild(lib)
		}
		if(slug.includes('SYSTEM1224')){
			let lib = document.createElement('script')
			lib.setAttribute('src', 'https://portal.combeenation.com/plugin/bnindustries/SYSTEM1224')
			document.head.appendChild(lib)
		}

		if(window.CbnPlugin){
			window.CbnPlugin.init()
		}
	},
	head() {
		if (this.blok.meta) {
			var meta = this.blok.meta
		}

		if (this.blok.overlap_content) {
			var bodyBgColor = JSON.parse('{ "color":"bg-beige" }')
		}

		const headObj = { ...bodyBgColor, ...meta }

		if (headObj) {
			return {
				bodyAttrs: {
					class: headObj.color
				},
				title: headObj.title,
				meta: [
					{
						hid: 'description',
						name: 'description',
						content: headObj.description
					},
					{
						hid: 'og:title',
						property: 'og:title',
						content: headObj.og_title
					},
					{
						hid: 'og:image',
						property: 'og:image',
						content: headObj.og_image
					},
					{
						hid: 'og:description',
						property: 'og:description',
						content: headObj.og_description
					},
					{
						hid: 'twitter:title',
						name: 'twitter:title',
						content: headObj.twitter_title
					},
					{
						hid: 'twitter:image',
						name: 'twitter:image',
						content: headObj.twitter_image
					},
					{
						hid: 'twitter:description',
						name: 'twitter:description',
						content: headObj.twitter_description
					}
				]
			}
		}
	},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		}
	},
	methods: {},
	props: ['blok']
}
