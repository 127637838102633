//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	components: {},
	data() {
		return {
			story:{}
		}
	},
	head() {
		return {
			title: '404'
		}
	},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		}
	},
	methods: {
		editable() {
			if(this.story && this.story.content){
				return this.story.content
			}
			return this;
		},
	},
	props: ['error'],
	mounted() {
		this.$storybridge.on(['input', 'published', 'change'], event => {
			if (event.action == 'input') {
				if (event.story.id === this.story.id) {
					this.story.content = event.story.content
				}
			} else if (!event.slugChanged) {
				window.location.reload()
			}
		})

		let version = 'publish';
		if(typeof window !== 'undefined'){
			const inEditor = window.Storyblok && window.Storyblok.inEditor;
			if(inEditor || window.location !== window.parent.location){
				version = 'draft';
			}
		}

		let path = '404-page';
		const api = this.$store.$storyapi;

		api
			.get(`cdn/stories/${path}`, {
				version: version,
				cv: this.$store.$storyapi.cacheVersion
			})
			.then(res => {
				console.debug(res);
				this.story=res.data.story;
			})
			.catch(res => {
				if (!res.response) {
					console.error(res)
				} else {
					console.error(res.response.data)
				}
				this.content = "Something went wrong.";
			});
	}
}
