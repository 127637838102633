//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null) {
			if (this.blok && this.blok.image && this.blok.image.filename) {
				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					fileType: fileType
				})
				return processedImage
			}
		}
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		description() {
			if (this.blok.description) {
				return this.$storyapi.richTextResolver.render(this.blok.description).replace(/\|/g, "<br />");
			}
		},
	}
}
