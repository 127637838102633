//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data() {
		return {
			linkArray: null
		}
	},
	props: ['blok'],
	methods: {},
	computed: {},
	created() {
		this.linkArray = []

		if (this.blok.item) {
			this.blok.item.forEach(item => {
				this.linkArray.push({ label: item.link_label, link: item.link.cached_url })
			})
		}
	}
}
