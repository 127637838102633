//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {
			hasErrors: false,
			errorServer: false,
			errorMessages: null,
			toggle: false,
			firstName: '',
			lastName: '',
			company: '',
			project: '',
			email: '',
			phone: '',
			shippingAddress: '',
			city: '',
			state: '',
			zip: '',
			country: '',
			profilesFinishes: '',
			comments: '',
			createAccount: false,
			password: '',
			confirmPassword: '',
			industry: ''
		}
	},
	props: ['blok'],
	computed: {
		formAction() {
			return process.env.requestFormAction
		},
		loggedIn() {
			return this.$auth.loggedIn
		}
	},
	created() {
		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},
	methods: {
		invalidateForm() {
			this.hasErrors = true
		},
		errors(fieldId) {
			// return formatted error messages for a given field

			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		submit() {
			// clear existing errors first
			this.hasErrors = false
			this.errorMessages = null

			// check for any additional frontend validation
			if (this.password != this.confirmPassword) {
				this.errorMessages = this.errorMessages || {}
				this.errorMessages['password_confirmation'] = ['Passwords must match.']
			}

			// if there are frontend errors, don't submit the form
			if (this.errorMessages !== null) return

			let postData = {
				firstname: this.firstName,
				lastname: this.lastName,
				company: this.company,
				project: this.project,
				email: this.email,
				phone: this.phone,
				shippingaddress: this.shippingAddress,
				city: this.city,
				state: this.state,
				zip: this.zip,
				country: this.country,
				createaccount: this.createAccount ? 1 : 0,
				password: this.password,
				password_confirmation: this.confirmPassword,
				page_url: this.$nuxt.$route.path,
				industry: this.industry
			}
			// @todo Is there a better way to check form element presence?
			if (this.profilesFinishes) {
				postData.profilesfinishes = this.profilesFinishes
			}
			if (this.comments) {
				postData.comments = this.comments
			}

			// submit the form
			this.$axios
				.post(this.formAction, postData, {
					debug: false
				})
				.then(data => {
					if (data.status == 201) {
						// If Create Account is checked, store it in localStorage so additional Thank You copy can be displayed.
						if (this.$refs.acctCheckbox && this.$refs.acctCheckbox.checked) {
							console.info('account created, fetching user')
							this.$auth.fetchUser()
							const passiveAcct = localStorage.getItem('passiveAcct')

							if (passiveAcct !== 'checked') {
								localStorage.setItem('passiveAcct', 'checked')
							}
						}

						this.$router.push('/resources/order-samples/thank-you')
					}
				})
				.catch(error => {
					// do nothing if there is no error object
                    console.error(error);
					if (!error) return

					// use server-side validation messages if they exist
					// otherwise use a generic error message
					if (error.response && error.response.data && error.response.data.errors) {
						this.errorMessages = error.response.data.errors
					} else {
						this.errorServer = true
					}
				})
		}
	},
	mounted() {}
}
