import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=28f2eeb7&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoClip: require('/home/webadmin/sites/bnind.com/bnfe/releases/20231207T215914/components/VideoClip.vue').default,Picture: require('/home/webadmin/sites/bnind.com/bnfe/releases/20231207T215914/components/Picture.vue').default})
