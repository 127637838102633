import { render, staticRenderFns } from "./GlobalModal.vue?vue&type=template&id=53a10f04&"
import script from "./GlobalModal.vue?vue&type=script&lang=js&"
export * from "./GlobalModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormContact: require('/home/webadmin/sites/bnind.com/bnfe/releases/20231207T215914/components/FormContact.vue').default,UserLogin: require('/home/webadmin/sites/bnind.com/bnfe/releases/20231207T215914/components/UserLogin.vue').default,Modal: require('/home/webadmin/sites/bnind.com/bnfe/releases/20231207T215914/components/Modal.vue').default})
