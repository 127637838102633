//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		snapToTop() {
			if(this.blok && this.blok.snap_to_top) {
				return ' snap';
			}
		},
        toggleMute() {
            let video=document.getElementById("csVideo");
            let btn=document.getElementById("bn-toggleMute");
            video.muted = !video.muted;
            btn.classList.toggle('unmuted');
        }
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		
	}
}
