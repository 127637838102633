//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	async mounted() {
		try {
			await this.$recaptcha.init()
		} catch (e) {
			console.log(e)
		}
	},
	components: {},
	data() {
		return {
			hasErrors: false,
			errorServer: false,
			errorMessages: null,
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			projectName: '',
			companyName: '',
			city: '',
			state: '',
			country: '',
			message: '',
			industry: '',
            loading:false
		}
	},
	props: ['blok'],
	computed: {
		formAction() {
			return process.env.contactFormAction
		},
		pathName() {
			return window.location.pathname
		}
	},
	created() {
		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},
	methods: {
		closeModal() {
			this.$bus.$emit('closeModal')
		},
		invalidateForm() {
			this.hasErrors = true
		},
		errors(fieldId) {
			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		submitValidatedForm(token) {
			console.log('recaptcha token: ' + token)
			console.log('this.formAction: ' + this.formAction)
			this.$axios.post(this.formAction, {
				token: token, // reCAPTCHA
				firstname: this.firstName,
				lastname: this.lastName,
				email: this.email,
				phone: this.phone,
				projectname: this.projectName,
				companyname: this.companyName,
				city: this.city,
				state: this.state,
				country: this.country,
				message: this.message,
				industry: this.industry,
				current_page: this.pathName
			}, {debug: false})
			.then(data => {
				this.loading = false;
				if (data.status == 201) {
					this.closeModal()
					this.$router.push('/about-b-n/thank-you')
				}
			})
			.catch(error => {
				this.loading = false;
				// do nothing if there is no error object
				if (!error) return

				// use server-side validation messages if they exist
				// otherwise use a generic error message
				if (error.response && error.response.data && error.response.data.errors) {
					this.errorMessages = error.response.data.errors
				} else {
					this.errorServer = true
				}
			})
		},
		submit() {
			console.log('submit method')
            this.loading = true;
			// clear existing errors
			this.errorMessages = null

			// if there are frontend errors, don't submit the form
			if (this.errorMessages !== null){
                this.loading = false;
                return;
            }

			//this.submitValidatedForm('test');
			//console.log('starting ready')
			let cb = this.submitValidatedForm
			grecaptcha.ready(function() {
				//console.log('grecaptcha.ready')
				grecaptcha.execute('6LfhUh0hAAAAABnqrdpdMIrEcR_RiZwEikb_i3WF', { action: 'submit' }).then(cb)
			})
		}
	},
	mounted() {
		const recaptchaScript = document.createElement('script');
		recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LfhUh0hAAAAABnqrdpdMIrEcR_RiZwEikb_i3WF');
		document.head.appendChild(recaptchaScript);
	}
}
