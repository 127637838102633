//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';

export default {
	components: {},
	data() {
		return {
			activeSection: 0
		}
	},
	props: ['blok'],
	methods: {
		hasItems(){
			return this.blok && this.blok.items.length > 0;
		},
		handleSectionTransition(id) {
			this.$forceUpdate();
			this.activeSection = id;
		},
		isCurrentSection(id) {
			return id === this.activeSection;
		},
	},
	mounted() {
		console.debug(this.blok);
		//TODO - ADD EVENT LISTENERS
		$(window).scroll(function(){
			var sections = $('.fp-child-static');
			sections.each(function(){
				var el = this;
				var top  = $(el).offset().top;
				var bottom = top +$(el).height();
				var scroll = $(window).scrollTop();
				if (scroll < top) {
					$(el).prev('.fp-child-fixed[index="0"]').addClass('initial');
				} else {
					$(el).prev('.fp-child-fixed[index="0"]').removeClass('initial');
				}
				if( scroll >= top && scroll <= bottom){
					$(el).prev('.fp-child-fixed').addClass('active');
					
				} else {
					$(el).prev('.fp-child-fixed').removeClass('active');	
				}
			
			if (scroll > bottom) {
				$(el).prev('.fp-child-fixed').addClass('end');
				} else {
				$(el).prev('.fp-child-fixed').removeClass('end');
				}
			})
		});
	},
	computed: {},
	destroyed() {
		//TODO - REMOVE ANY LISTENERS HERE
	},
}
