//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		Cards: () => import('@/components/Cards.vue')
	},
	data() {
		return {
		}
	},
	props: ['blok'],
	computed: {
		products() {
			return this.$store.getters['products/getBookmarkedProducts']
		},
		productsCardData() {
			const data = {
				type: 'products',
				autocrop: ['oneone'],
				dataSize: 'col4',
				columns: ['col4'],
				section_title: 'Bookmarked Products'
			}
			data.uuids = Array.from(this.products, item => item.uuid)
			return data
		},
		solutions() {
			return this.$store.getters['solutions/getBookmarkedSolutions']
		},
		solutionsCardData() {
			const data = {
				type: 'solutions',
				autocrop: ['oneone'],
				dataSize: 'col4',
				columns: ['col4'],
				section_title: 'Bookmarked Solutions'
			}
			data.uuids = Array.from(this.solutions, item => item.uuid)
			return data
		}
	},
	created() {
	},
	methods: {
	}
}
