//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		Hero: () => import('~/components/Hero.vue')
	},
	data() {
		return {
			autoplay: this.blok.autoplay,
			slide: 0
		}
	},
	computed: {
		checkAutoplay() {
			if (process.client) {
				if (!('ontouchstart' in document.documentElement) && this.$motionPref == true && this.autoplay == true) {
					return 'carousel-container--autoplay'
				}
			}
		},
		timing() {
			return this.blok.autoplay_time
		}
	},
	props: ['blok'],
	methods: {
		activeItem: function() {
			let active = this.carousel.querySelector('li.active')
			if (active != null && this.blok.carousel_item.length > 1) {
				let index = [...active.parentElement.children].indexOf(active)

				// Manage button activation
				if (index == this.carouselItems.length - 1) {
					this.btnNext.setAttribute('disabled', 'disabled')
				} else if (index == 0) {
					this.btnPrev.setAttribute('disabled', 'disabled')
				} else {
					this.btnNext.removeAttribute('disabled')
					this.btnPrev.removeAttribute('disabled')
				}

				// Update index
				if (index > this.blok.carousel_item.length - 1) {
					this.slide = 0
				}
				this.slide = index
			}
		},
		autoplayInit: function() {
			this.timer = setInterval(() => this.autoplayMove(), this.blok.autoplay_time)
		},
		autoplayMove: function(index) {
			// Move 1 if not last
			if (this.slide < this.carouselItems.length - 1) {
				index = 1
			}

			// Move slide
			this.jumpTo(index)
		},
		autoplayStop: function() {
			this.autoplay = false
			clearInterval(this.timer)
		},
		changeSlide: function(e) {
			// Figure out item width
			const scrollValue = this.carouselItems[0].offsetWidth

			// Move if prev/next is clicked
			const width = scrollValue,
				delta = e.target.dataset.delta
			this.carousel.scrollTo(this.carousel.scrollLeft + width * delta, 0)

			this.autoplayStop()
		},
		checkUserHScroll: function() {
			const mouseEvent = e => {
				if (e.deltaX && e.deltaY < 10) {
					// Stope Autoplay
					this.autoplayStop()
					// Remove listener
					this.carouselContainer.removeEventListener('wheel', mouseEvent)
				}
			}
			// Add listener
			this.carouselContainer.addEventListener('wheel', mouseEvent, false)
		},
		jumpTo: function(index) {
			let delta = 1

			// Movement if No Autoplay
			if (!this.autoplay) {
				// Manual Forward/Backward Movement
				if (index < this.slide) {
					// Move backwards
					delta = -1
					index = this.slide - index
				} else {
					// Move forwards
					index = index - this.slide
				}
			}

			// Figure out item width
			const scrollValue = this.carouselItems[0].offsetWidth

			// Move when bullet is clicked
			this.carousel.scrollTo(this.carousel.scrollLeft + scrollValue * (index * delta), 0)
		},
		skipBtn: function() {
			// Set up skip button only if data-description is defined
			if (this.carouselContainer.nextElementSibling !== null) {
				const nextEl = this.carouselContainer.nextElementSibling
				this.nextEl = nextEl

				// Skip Carousel Button
				this.btnSkip.addEventListener('click', e => {
					this.nextEl.setAttribute('tabindex', '0')
					this.nextEl.focus()
				})
				this.nextEl.addEventListener('click', e => {
					this.nextEl.removeAttribute('tabindex', '0')
				})
			}
		}
	},
	mounted() {
		this.carouselContainer = this.$refs.carouselContainer
		this.carousel = this.$refs.carousel
		this.carouselItems = this.carousel.querySelectorAll('li')
		this.btnNext = this.$refs.nextBtn
		this.btnPrev = this.$refs.prevBtn
		this.btnSkip = this.$refs.skipBtn

		// Autoplay
		if (this.autoplay == true && !('ontouchstart' in document.documentElement)) {
			this.autoplayInit()
		}

		// Stops autoplay if user scrolls carousel
		this.checkUserHScroll()

		// Skip Button
		this.skipBtn()

		// Observe the slide change
		const threshold = 0.5
		const options = {
			root: this.carousel,
			threshold
		}
		let observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.intersectionRatio >= threshold) {
					entry.target.classList.add('active')
				} else {
					entry.target.classList.remove('active')
				}
			})
			// Update active slide
			this.activeItem()
		}, options)
		;[].map.call(this.carouselItems, function(el) {
			observer.observe(el)
		})
	}
}
