//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import AOS from 'aos';

export default {
	components: {
	},
	data() {
		return {
			vCenterMain: false
		}
	},
	head() {
		if (this.blok.meta) {
			var meta = this.blok.meta
		}

		if (this.blok.overlap_content) {
			var bodyBgColor = JSON.parse('{ "color":"bg-beige" }')
		}

		const headObj = { ...bodyBgColor, ...meta }

		if (headObj) {
			return {
				bodyAttrs: {
					class: headObj.color
				},
				title: headObj.title,
				meta: [
					{
						hid: 'description',
						name: 'description',
						content: headObj.description
					},
					{
						hid: 'og:title',
						property: 'og:title',
						content: headObj.og_title
					},
					{
						hid: 'og:image',
						property: 'og:image',
						content: headObj.og_image
					},
					{
						hid: 'og:description',
						property: 'og:description',
						content: headObj.og_description
					},
					{
						hid: 'twitter:title',
						name: 'twitter:title',
						content: headObj.twitter_title
					},
					{
						hid: 'twitter:image',
						name: 'twitter:image',
						content: headObj.twitter_image
					},
					{
						hid: 'twitter:description',
						name: 'twitter:description',
						content: headObj.twitter_description
					}
				]
			}
		}
	},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		}
	},
	methods: {
		centerIt() {
			this.vCenterMain = true
		}
	},
	props: ['blok'],
	created() {
		if (process.client) {
			if (this.$route.path !== '/create-account') this.$intent.clear()
		}

		if (this.loggedIn && !this.$store.getters['bookmarks/hasBeenFetched']) {
			const user = this.$store.$auth.user
			if (user && user.favorites) {
				const bookmarks = []
				user.favorites.forEach(bookmark => {
					bookmarks.push(bookmark.url)
				})

				this.$store.dispatch('bookmarks/getBookmarksFromUser', bookmarks)
			}
		}
	},
	mounted() {
		AOS.init(); 
		
		console.debug(this.blok);
		$(".interactive-block__selector--toggle").on("click", function(){
			$(this).next('.interactive-block__selector--buttons').slideToggle();
			$(this).toggleClass('toggled');
		});

		if($('.prod-tiles__carousel').length){
			let carouselItems = $('.prod-tiles__carousel .tile-item').length;
			let carouselWidth;
			if($(window).width() > 768){
				carouselWidth = carouselItems * 600 + carouselItems * 35 - 35;
			} else {
				carouselWidth = carouselItems * 300 + carouselItems * 35 - 35;
			}
			 
			$('.prod-tiles__carousel').css({'width': carouselWidth + 'px'});
			let scrollWidth = $('.prod-tiles__carousel--scroll').width() + $('.prod-tiles__carousel--scroll').offset().left;
			// $('.prod-tiles__carousel--scroll').css({
			// 	'min-width':  scrollWidth + 'px'
			// });

		}

		// $(window).resize(function(){
		// 	if($('.prod-tiles__carousel').length){
		// 		let scrollWidth = $('.prod-tiles__wrapper.carousel').width() + $('.prod-tiles__carousel--scroll').offset().left;
		// 		$('.prod-tiles__carousel--scroll').css({
		// 			'min-width':  scrollWidth + 'px'
		// 		});
		// 	}
		// });

		$('.interactive-block__fullscreen').on('click', function(){
			$(this).closest('.selected-item').addClass('smaller-index');
			$(this).next('.interactive-block__fullscreen__div').fadeIn();
			$('body').addClass('noscroll');
		});

		$('.interactive-block__fullscreen__div__close').on('click', function(){
			$(this).closest('.selected-item').removeClass('smaller-index');
			$(this).closest('.interactive-block__fullscreen__div').fadeOut();
			$('body').removeClass('noscroll');
		});

	}
}
