//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// work in progress - scratch is in UserProfile component for now
export default {
	components: {},
	data() {
		return {
			toggleForms: true,
			toogleForgotPassword: false,
			login: {
				errors: false,
				email: '',
				password: '',
				rememberMe: false
			},
			forgotPassword: {
				errors: false,
				email: ''
			},
			errorServer: false,
			errorMessages: null
		}
	},
	props: ['blok'],
	computed: {},
	methods: {
		closeModal() {
			this.$bus.$emit('closeModal')
		},
		handleIntent() {
			if (process.client) {
				this.$intent.handle()
			}
		},
		invalidateForgotPassworForm() {
			this.forgotPassword.errors = true
		},
		invalidateLoginForm() {
			this.login.errors = true
		},
		handleLogin() {
			// clear existing errors first
			this.errorMessages = null

			this.$auth
				.loginWith('laravelSanctum', {
					data: this.login
				})
				.then(response => {
					this.closeModal()
					this.handleIntent()
                    this.$bus.$emit('loginSuccess');
				})
				.catch(error => {
					// do nothing if there is no error object
					if (!error) return

					// use server-side validation messages if they exist
					// otherwise use a generic error message
					if (error.response && error.response.data && error.response.data.errors) this.errorMessages = error.response.data.errors
					else this.errorServer = true
				})
		},
		errors(fieldId) {
			// return formatted error messages for a given field

			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		resetPassword() {
			this.$axios
				.post(process.env.requestPasswordChangeEndpoint, {
					email: this.forgotPassword.email
				})
				.then(response => {
					// @todo Show success message: "We have emailed you a reset password link"
					this.toogleForgotPassword = true
				})
				.catch(error => {
					// use server-side validation messages if they exist
					// otherwise use a generic error message
					if (error.response && error.response.data && error.response.data.errors) this.errorMessages = error.response.data.errors
					else this.errorServer = true
				})
		}
	},
	mounted() {}
}
