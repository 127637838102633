export const state = () => ({
	fetched: false,
	bookmarks: []
})

export const actions = {
	async fetchAllBookmarks({ state, commit }) {
		console.log('FETCHING BOOKMARKS TO STORE', state.fetched);

		if (state.fetched) return
		state.fetched = true

		// using $axios ensure the credentials are passed as configured in nuxt.config.js
		const bookmarkData = await this.$axios.$get(process.env.favoritesListEndpoint)
		const bookmarks = []

		bookmarkData.forEach(bookmark => {
			bookmarks.push(bookmark.url)
		});

		console.info('BOOKMARKS:', bookmarks);

		commit('setBookmarks', bookmarks)
	},
	getBookmarksFromUser({ state, commit }, bookmarks) {
		if (state.fetched) return
		commit('setFetched', true)

		commit('setBookmarks', bookmarks)
	},
	addBookmark({ state, commit }, { bookmark }) {
		if (state.bookmarks.includes(bookmark))
			return

		this.$axios
			.put(
				process.env.favoritesFormAction,
				{
					url: bookmark
				},
				{
					debug: false
				}
			)
			.then(data => {
				console.log('response ok', data);

				/* 
				TODO
				check status
				0 - saved successful, add to store
				1 - failed, already exists
				*/

				if (!state.bookmarks.includes(bookmark))
					commit('addBookmark', bookmark)
			})
			.catch(error => {
				console.log('response error', error);
			})
	},
	removeBookmark({ state, commit }, { bookmark }) {
		if (!state.bookmarks.includes(bookmark))
			return

		this.$axios
			.delete(
				process.env.favoritesFormAction,
				{
					data: {
						url: bookmark
					}
				},
				{
					debug: false
				}
			)
			.then(data => {
				console.log('response ok', data);

				/* 
				TODO
				check status
				0 - saved successful, add to store
				1 - failed, already exists
				*/

				if (state.bookmarks.includes(bookmark))
					commit('removeBookmark', bookmark)
			})
			.catch(error => {
				console.log('response error', error);
			})		
	}
}

export const getters = {
	getAllBookmarks: state => {
		return state.bookmarks
	},
	getBookmarkBySlug: state => (id) => {
		return state.bookmarks.find(item => item === id)
	},
	hasBeenFetched: state => {
		return state.fetched
	}
}

export const mutations = {
	setBookmarks(state, bookmarks) {
		state.bookmarks = bookmarks
	},
	addBookmark(state, bookmark) {
		state.bookmarks.push(bookmark)
	},
	removeBookmark(state, bookmark) {
		const index = state.bookmarks.indexOf(bookmark)
		state.bookmarks.splice(index, 1)
	},
	setFetched(state, fetched) {
		state.fetched = fetched
	}
}
