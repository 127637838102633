//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {
			vCenterMain: false
		}
	},
	head() {
		if (this.blok.meta) {
			var meta = this.blok.meta
		}

		if (this.blok.overlap_content) {
			var bodyBgColor = JSON.parse('{ "color":"bg-beige" }')
		}

		const headObj = { ...bodyBgColor, ...meta }

		if (headObj) {
			return {
				bodyAttrs: {
					class: headObj.color
				},
				title: headObj.title,
				meta: [
					{
						hid: 'description',
						name: 'description',
						content: headObj.description
					},
					{
						hid: 'og:title',
						property: 'og:title',
						content: headObj.og_title
					},
					{
						hid: 'og:image',
						property: 'og:image',
						content: headObj.og_image
					},
					{
						hid: 'og:description',
						property: 'og:description',
						content: headObj.og_description
					},
					{
						hid: 'twitter:title',
						name: 'twitter:title',
						content: headObj.twitter_title
					},
					{
						hid: 'twitter:image',
						name: 'twitter:image',
						content: headObj.twitter_image
					},
					{
						hid: 'twitter:description',
						name: 'twitter:description',
						content: headObj.twitter_description
					}
				]
			}
		}
	},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		}
	},
	methods: {
		centerIt() {
			this.vCenterMain = true
		}
	},
	props: ['blok'],
	created() {
		if (process.client) {
			// clear intent unless it's the create account page
			if (this.$route.path !== '/create-account') this.$intent.clear()
		}

		if (this.loggedIn && !this.$store.getters['bookmarks/hasBeenFetched']) {
			console.log('TRY TO FETCH BOOKMARKS (we are logged in)')
			const user = this.$store.$auth.user
			// const user = {
			// 	email: 'foo',
			// 	favorites: [
			// 		{id: 1, url: 'foo1'},
			// 		{id: 2, url: 'foo2'},
			// 		{id: 3, url: 'foo3'}
			// 	]
			// }
			if (user && user.favorites) {
				const bookmarks = []
				user.favorites.forEach(bookmark => {
					bookmarks.push(bookmark.url)
				})

				console.log(bookmarks)
				this.$store.dispatch('bookmarks/getBookmarksFromUser', bookmarks)
			}
		}
	}
}
