//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['contact_text'],
	methods: {
		contactForm: function() {
			this.showNav = false
			this.$bus.$emit('openContactModal')
		}
	}
}
