/* Usage: if (this.$motionPref == true) {} */
import Vue from 'vue'
export const a11y = {
    install(Vue, options) {
        if (process.client) {
            Vue.prototype.$motionPref = window.matchMedia('(prefers-reduced-motion: no-preference)').matches
        }
    }
}
Vue.use(a11y)
