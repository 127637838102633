//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		ListIconItem: () => import('@/components/ListIconItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
