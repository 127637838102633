//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
        image(fileType = null) {
			if (this.blok && this.blok.image && this.blok.image.filename) {
				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					fileType: fileType
				})
				return processedImage
			}
		}, 
        exitNav: function () {
            let header = document.querySelector('header.global-header');
            if(header) {
                header.classList.remove('global-nav--interacting');
            }
        }
	},
	mounted() {
	},
	computed: {
	}
}
