//
//
//

// work in progress - scratch is in UserProfile component for now
export default {
	components: {},

	data() {
		return {}
	},

	props: ['blok'],

	methods: {
		handleLogout() {
			this.$auth.logout()
		}
	},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		}
	}
}
