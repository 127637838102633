//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let width, height

			if (this.imageSource && this.imageSource.filename) {
				// default size
				width = 130
				height = 130

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.imageSource.filename, {
					size: width + 'x' + height,
					focus: this.imageSource.focus,
					fileType: fileType
				})
				return processedImage
			}
		},
		getProductSlug(){
            if (this.blok.product){
                const product = this.$store.getters['products/getProductByUUID'](this.blok.product.id)
                return product.full_slug;
            }   
        }
	},
	mounted() {},
	computed: {
		product() {
			if (this.blok.product) return this.$store.getters['products/getProductByUUID'](this.blok.product.id)
		},
		imageSource() {
			if (this.product && this.product.content && this.product.content.data) {
				let thumb

				this.product.content.data.forEach(item => {
					if (item.component === 'product_data') {
						if (item.thumbnail && item.thumbnail.filename)
							thumb = item.thumbnail
					}
				})

				if (thumb)
					return thumb
			}
		}
	}
}
