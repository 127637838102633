//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		Bookmark: () => import('~/components/Bookmark.vue')
	},
	data() {
		return {
			data: null,
			show: true,
            loggedIn:false
		}
	},
	props: ['blok', 'uuid', 'type', 'galleryCardIndex', 'autocrop', 'columns'],
	methods: {
		showLoginModal() {
			// save intent object
			this.$intent.set('download', this.$route.path, { file: this.blok.file.filename })
			this.$bus.$emit('openModal')
		},
		galleryClick() {
			if (this.galleryCardIndex !== undefined) this.$emit('cardClick', this.galleryCardIndex)
		},
		editable() {
			if(this.blok){
				return this.blok
			}
			return this;
		},
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			let ratio = 'oneone' // default ratio is 1:1 square
			let width, height

			if (this.data && this.data.image && this.data.image.filename) {
				// grab autocrop value if it exists
				if (this.autocrop && this.autocrop.length) {
					ratio = this.autocrop[0]
				}

				// if this grid has no columns, return the original image
				if (!this.columns || !this.columns.length) return this.data.image.filename

				// get the image width based on grid columns
				switch (this.columns[0]) {
					case 'col2':
						width = 652
						break
					case 'col3':
						width = 428
						break
					case 'col4':
					case 'col4-hscroll':
						width = 316
						break
					case 'col6':
						width = 204
						break
					default:
						return this.data.image.filename
				}

				// get the image height based on the image ratio and width
				switch (ratio) {
					case 'oneone': // 1:1
						height = width
						break
					case 'threetwo': // 3:2
						height = Math.round((width * 2) / 3)
						break
					case 'none': // variable height
						height = 0
						break
					default:
						return this.data.image.filename
				}

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.data.image.filename, {
					size: width + 'x' + height,
					focus: this.data.image.focus,
					fileType: fileType
				})
				return processedImage
			}
		},
        getLoggedIn(){
            return this.$auth.loggedIn;
        }
	},
	created() {
		// see if there's a link uuid to use
		let linkId
		if (this.uuid) linkId = this.uuid
		else if (this.blok && !this.blok.is_custom && this.blok.link && this.blok.link.id) linkId = this.blok.link.id

		if (linkId) {
			// uuids are used for autogenerated cards
			let detail, linkType

			if (this.type) {
				linkType = this.type
			} else {
				if (this.blok.link.cached_url.startsWith('products/')) linkType = 'products'
				else if (this.blok.link.cached_url.startsWith('solutions/')) linkType = 'solutions'
			}

			switch (linkType) {
				case 'solutions':
					detail = this.$store.getters['solutions/getSolutionByUUID'](linkId)
					this.data = {}

					// get solution data
					if (detail && detail.content.data) {
						detail.content.data.forEach(component => {
							if (component.component === 'solution_data') {
								if (component.thumbnail && component.thumbnail.filename) this.data.image = component.thumbnail

								// use verticals as subtitle
								if (component.verticals) {
									const verticals = []
									component.verticals.forEach(vertical => {
										verticals.push(this.$store.getters['solutions/getCategoryNameByValue'](vertical))
									})
									this.data.sub_title = verticals.join(', ')
								}
							}
						})
					}
					break
				case 'products':
					detail = this.$store.getters['products/getProductByUUID'](linkId)
					this.data = {}

					// get product data
					if (detail && detail.content.data) {
						detail.content.data.forEach(component => {
							if (component.component === 'product_data') {
								this.data.blurb = component.blurb
								if (component.thumbnail && component.thumbnail.filename) this.data.image = component.thumbnail
								if(component.data_title){
									this.data.title = component.data_title;
								}
							}
						})
					}
					break
				default:
					break
			}

			if (!detail) return

			// get title from page header component
			if(detail.content.body){
				detail.content.body.forEach(component => {
					if (component.component === 'page_header') {
						this.data.title = component.title
					}
				})
			}
			
			// get url from full slug
			this.data.link = {
				cached_url: detail.full_slug
			}

			// placeholder thumbnail image
			if (!this.data.image) {
				this.data.image = {
					filename: 'https://a.storyblok.com/f/102348/316x316/b900e6733a/316x316.png',
					alt: 'placeholder image'
				}
			}

			// these are bookmarkable
			this.data.bookmark = true

			// handle filter events
			if (linkType === 'solutions') {
				// products used filter event handler
				this.$bus.$on('solutionsFilterProductsUsed', filterArray => {
					// if no filters active, show all cards
					if (!filterArray.length) {
						this.show = true
						return
					}

					const solution = this.$store.getters['solutions/getSolutionByUUID'](linkId)
					const body = solution.content.body
					let match = false

					body.forEach(item => {
						if (item.component === 'solution_details') {
							const products = item.products

							if (products) {
								products.forEach(item => {
									if (item.component === 'solution_details_product') {
										if (item.product && item.product.id) {
											if (filterArray.includes(item.product.id)) match = true
										}
									}
								})
							}
						}
					})

					this.show = match
				})
			} else if (linkType === 'products') {
				// applications filter event handler
				this.$bus.$on('productsFilterApplication', filterArray => {
					// if no filters active, show all cards
					if (!filterArray.length) {
						this.show = true
						return
					}

					const product = this.$store.getters['products/getProductByUUID'](linkId)
					const data = product.content.data
					let match = false

					if (data) {
						for (let j = 0; j < data.length; j++) {
							if (data[j].component === 'product_data') {
								const applications = data[j].applications
								for (let k = 0; k < applications.length; k++) {
									const application = this.$store.getters['products/getProductApplicationByValue'](applications[k])
									if (filterArray.includes(application.id.toString())) {
										match = true
										break
									}
								}
							}
						}
					}

					this.show = match
				})
			}
		} else if (this.blok) {
			// regular blok data for manual SB editing
			this.data = this.blok
		}

        this.$bus.$on('loginSuccess', data => {
            this.loggedIn = this.getLoggedIn();
        });
	},
	computed: {
		isFile() {
			if (this.data && this.data.file && this.data.file.filename) return true
		},
		description() {
			if (this.blok && this.blok.description && this.blok.description.content[0].content && this.blok.is_custom) {
				return this.$storyapi.richTextResolver.render(this.blok.description)
			}
		},
		externalDownload() {
			if (this.blok && this.blok.link) {
				if (this.blok.link.cached_url.includes('.pdf') || this.blok.link.cached_url.includes('.zip')) {
					return true
				}
			}
		}
	},
    mounted() {
        this.loggedIn = this.getLoggedIn();
    }
}
