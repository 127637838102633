//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {
		this.$emit('verticallyCenter')
	},
	computed: {
		acctMessage() {
			if (process.client && localStorage.getItem('passiveAcct') == 'checked') {
				return true
			}
		},
		text1() {
			if (this.blok.text_1 && this.blok.text_1.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.text_1)
			}
		},
		text2() {
			if (this.blok.text_2 && this.blok.text_2.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.text_2)
			}
		}
	}
}
