//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {
			hasErrors: false,
			errorServer: false,
			email: '',
			password: '',
			confirmPassword: '',
			firstName: '',
			lastName: '',
			city: '',
			state: '',
			country: '',
			companyName: '',
			project: '',
			industry: '',
			errorMessages: null
		}
	},
	props: ['blok'],
	computed: {
		formAction() {
			return process.env.registerFormAction
		},
		disclaimer() {
			if (this.blok.disclaimer) {
				return this.$storyapi.richTextResolver.render(this.blok.disclaimer)
			}
		}
	},
	created() {
		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},
	methods: {
		invalidateForm() {
			this.hasErrors = true
		},
		errors(fieldId) {
			// return formatted error messages for a given field

			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		submit() {
			// clear existing errors first
			this.errorMessages = null

			// check for any additional frontend validation
			if (this.password != this.confirmPassword) {
				this.errorMessages = this.errorMessages || {}
				this.errorMessages['password_confirmation'] = ['Passwords must match.']
			}

			// if there are frontend errors, don't submit the form
			if (this.errorMessages !== null) return

			// submit the form
			this.$axios
				.post(
					this.formAction,
					{
						email: this.email,
						password: this.password,
						password_confirmation: this.confirmPassword,
						firstname: this.firstName,
						lastname: this.lastName,
						city: this.city,
						state: this.state,
						country: this.country,
						company: this.companyName,
						project: this.project,
						industry: this.industry
					},
					{
						debug: false
					}
				)
				.then(data => {
					// 201 created response
					if (data.status == 201) {
						// user is logged in at this point
						this.$auth
							.loginWith('laravelSanctum', {
								data: {
									email: this.email,
									password: this.password
								}
							})
							.then(response => {
								console.info('user logged in', response)
								// if an intent exists, handle it
								// otherwise go to thank you page
								if (process.client && this.$intent.exists()) {
									this.$intent.handle()
								} else {
									this.$router.push('/account/thank-you')
								}
							})
					} else {
					}
				})
				.catch(error => {
					// do nothing if there is no error object
					if (!error) return

					// use server-side validation messages if they exist
					// otherwise use a generic error message
					if (error.response && error.response.data && error.response.data.errors) this.errorMessages = error.response.data.errors
					else this.errorServer = true
				})
		}
	},
	mounted() {}
}
