export const state = () => ({
	footerData: []
})

export const actions = {
	async fetchAllFooterData({ state, commit }) {
		let footerData = await this.$axios.$get('https://api.storyblok.com/v1/cdn/stories/global-footer?token=' + process.env.storyBlokAPIKey + '&version=' + process.env.storyBlokAPIVersion, { useCache: false })

		commit('setFooterData', footerData)
	}
}

export const getters = {
	getFooterData: state => {
		return state.footerData
	}
}

export const mutations = {
	setFooterData(state, footerData) {
		state.footerData = footerData
	}
}
