//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ["blok"],
    data() {
        return {
            interactingWithNav: "",
            showNav: false,
            ariaNav: false,
            ariaSubNav: true,
            tabSubNav: false,
            toggleEvent: ""
        };
    },
    computed: {
        navData() {
            return this.$store.getters["globalHeader/getNavData"];
        },
        loggedIn() {
            return this.$auth.loggedIn;
        }
    },
    methods: {
        exitNav: function () {
            this.interactingWithNav = false;
            this.buttonText = "Menu";
            this.showNav = false;
            this.ariaNav = false;
            this.ariaSubNav = true;
        },
        toggleSubNav: function (event) {
            this.ariaNav = !this.ariaNav;
            this.ariaSubNav = !this.ariaSubNav;
            this.tabSubNav = !this.tabSubNav;
            if (process.client) {
                if (window.matchMedia("(max-width: 1179px)").matches) {
                    event.target.classList.toggle("global-subnav--open");
                }
                if (window.matchMedia("(min-width: 1180px)").matches) {
                    this.interactingWithNav = false;
                    this.showNav = false;
                    event.target.classList.remove("global-subnav--open");
                }
            }
        },
        goBack: function (index) {
            this.ariaNav = false;
            this.ariaSubNav = true;
            this.tabSubNav = false;
            this.$refs.topLevelLink[index].$el.classList.remove("global-subnav--open");
        },
        logIn: function () {
            this.showNav = false;
            this.$bus.$emit("openModal");
        },
        getLinks() {
            if (this.navData.story && this.navData.story.content) {
                return this.navData.story.content.globalnav_links;
            }
            return {};
        },
    },
    mounted() {
        // this.$store.dispatch("globalHeader/fetchAllNavData");
        // Reset Header when browser is resized between small and large breakpoint
        if (process.client) {
            const mq = window.matchMedia("(min-width: 1180px)");
            const resetHeader = () => {
                if (mq.matches) {
                    this.buttonText = "Menu";
                    this.showNav = false;
                    this.ariaNav = false;
                    this.ariaSubNav = true;
                    this.tabSubNav = true;
                    this.toggleEvent = "click";
                    // Reset Sub-Nav if Open
                    if (this.$refs.topLevelLink) {
                        Array.prototype.forEach.call(this.$refs.topLevelLink, function (el) {
                            el.$el.classList.remove("global-subnav--open");
                        });
                    }
                }
                else {
                    this.tabSubNav = false;
                    this.toggleEvent = "";
                }
            };
            mq.addListener(resetHeader);
            resetHeader();
        }
        // Handle Escape Key
        document.addEventListener("keydown", e => {
            if (e.key == "Escape" && this.showNav == true) {
                // Close Nav
                this.exitNav();
            }
        });
    }
}
