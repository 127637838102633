export const func = {
	imageService: (image, props) => {
		const originalBase = 'https://a.storyblok.com'
		const imageServiceBase = 'https://img2.storyblok.com'

		// if no props, no need to use image service
		if (!props) return image

		// confirm it's a valid SB image
		if (!image.startsWith(originalBase)) return image

		const resource = image.split(originalBase)[1]

		// image size
		let size = ''
		if (props.size) size = '/' + props.size

		// image filters
		let filters = ''
		const filtersArray = []
		if (props.focus) filtersArray.push(`focal(${props.focus})`)
		if (props.fileType) filtersArray.push(`format(${props.fileType})`)
		if (filtersArray.length) filters = '/filters:' + filtersArray.join(':')

		//console.log(`${imageServiceBase}${size}${filters}${resource}`);

		return `${imageServiceBase}${size}${filters}${resource}`
	}
}
