//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Portal } from '@linusborg/vue-simple-portal'

export default {
	components: {
		CardsItem: () => import('@/components/CardsItem.vue'),
		Portal
	},
	data() {
		return {
			galleryImages: null,
			galleryCardIndex: null
		}
	},
	props: ['blok', 'inAccordion', 'cardData'],
	methods: {
		cardClick(galleryCardIndex) {
			if (this.blok.is_gallery) {
				this.galleryCardIndex = galleryCardIndex
			}
		},
		galleryClose() {
			this.galleryCardIndex = null
		},
		editable() {
			if(this.blok){
				return this.blok
			}
			return {};
		},
		customClass() {
			if(this.blok && this.blok.custom_class) {
				return this.blok.custom_class
			}
			 return '';
		}
	},
	created() {
		// initialize and popoulate gallery array
		if (this.blok && this.blok.item) {
			this.galleryImages = []
			this.blok.item.forEach(card => {
				this.galleryImages.push({
					title: card.gallery_caption ? card.gallery_caption : null,
					url: card.gallery_image && card.gallery_image.filename ? card.gallery_image.filename : card.image.filename
				})
			})
		}
	},
	computed: {
		disclaimer() {
			if (this.blok.disclaimer) {
				return this.$storyapi.richTextResolver.render(this.blok.disclaimer)
			}
		}
	}
}
