//
//
//
//
//
//
//

export default {
	components: {
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
	}
}
