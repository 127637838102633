export const state = () => ({
	companyTypes: []
})

export const actions = {
	async fetchAllCompanyTypes({ state, commit }) {
		let companyTypes = await this.$axios.$get('https://api.storyblok.com/v1/cdn/datasource_entries?datasource=company-type&token=' + process.env.storyBlokAPIKey, { useCache: false })

		commit('setCompanyTypes', companyTypes)
	}
}

export const getters = {
	getCompanyTypes: state => {
		return state.companyTypes
	}
}

export const mutations = {
	setCompanyTypes(state, companyTypes) {
		state.companyTypes = companyTypes
	}
}
