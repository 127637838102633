import Vue from 'vue'
import Page from '~/components/Page.vue'
import PageHome from '~/components/PageHome.vue'
import PageConfigurator from '~/components/PageConfigurator.vue'
import Page404 from '~/components/Page404.vue'
import PageError from '~/components/PageError.vue'
import PageRollingOffice from '~/components/PageRollingOffice.vue'
import PageCustomServices from '~/components/PageCustomServices.vue'
import CustomServicesHero from '~/components/CustomServices/CustomServicesHero.vue'
import CustomServicesText from '~/components/CustomServices/CustomServicesText.vue'
import CustomServicesImageText from '~/components/CustomServices/CustomServicesImageText.vue'
import CustomServicesImage from '~/components/CustomServices/CustomServicesImage.vue'
import CustomServicesTextOnImage from '~/components/CustomServices/CustomServicesTextOnImage.vue'

import Accordion from '~/components/Accordion.vue'
import AccordionItem from '~/components/AccordionItem.vue'
import Bio from '~/components/RollingOffice/Bio.vue'
import BioItem from '~/components/RollingOffice/BioItem.vue'
import Blockquote from '~/components/Blockquote.vue'
import Bookmark from '~/components/Bookmark.vue'
import Breadcrumb from '~/components/Breadcrumb.vue'
import Callout from '~/components/Callout.vue'
import Cards from '~/components/Cards.vue'
import CardsItem from '~/components/CardsItem.vue'
import Carousel from '~/components/Carousel.vue'
import ContactButton from '~/components/ContactButton.vue'
import ContactInfo from '~/components/ContactInfo.vue'
import Course from '~/components/Course.vue'
import EmailSignUp from '~/components/EmailSignUp.vue'
import FormContact from '~/components/FormContact.vue'
import FormCreateAccount from '~/components/FormCreateAccount.vue'
import FormRequest from '~/components/FormRequest.vue'
import FullPageScroll from '~/components/FullPageScroll.vue'
import Gallery from '~/components/Gallery.vue'
import GDPR from '~/components/GDPR.vue'
import HeaderContactButton from '~/components/HeaderContactButton.vue'
import Hero from '~/components/Hero.vue'
import Home from '~/components/Home.vue'
import InteractiveImage from '~/components/RollingOffice/InteractiveImage.vue'
import InteractiveImageItem from '~/components/RollingOffice/InteractiveImageItem.vue'
import ImageFeature from '~/components/ImageFeature.vue'
import ImageGrid from '~/components/ImageGrid.vue'
import Instagram from '~/components/Instagram.vue'
import LayoutAside from '~/components/LayoutAside.vue'
import ListActions from '~/components/ListActions.vue'
import ListActionsItem from '~/components/ListActionsItem.vue'
import ListDetails from '~/components/ListDetails.vue'
import ListDetailsItem from '~/components/ListDetailsItem.vue'
import ListIcon from '~/components/ListIcon.vue'
import ListIconItem from '~/components/ListIconItem.vue'
import Media from '~/components/RollingOffice/Media.vue'
import MediaAndText from '~/components/RollingOffice/MediaAndText.vue'
import TextWithImage from '~/components/RollingOffice/TextWithImage.vue'
import MessageBlock from '~/components/MessageBlock.vue'
import PageHeader from '~/components/PageHeader.vue'
import PageHeaderAccount from '~/components/PageHeaderAccount.vue'
import Picture from '~/components/Picture.vue'
import ProductApplications from '~/components/ProductApplications.vue'
import ProductData from '~/components/ProductData.vue'
import ProductDetails from '~/components/ProductDetails.vue'
import ProductsFilter from '~/components/ProductsFilter.vue'
import ProductsList from '~/components/ProductsList.vue'
import ResetPassword from '~/components/ResetPassword.vue'
import SectionIntro from '~/components/SectionIntro.vue'
import Slider from '~/components/Slider.vue'
import SliderItem from '~/components/SliderItem.vue'
import Social from '~/components/Social.vue'
import SolutionData from '~/components/SolutionData.vue'
import SolutionDetails from '~/components/SolutionDetails.vue'
import SolutionDetailsProduct from '~/components/SolutionDetailsProduct.vue'
import SolutionsFilter from '~/components/SolutionsFilter.vue'
import SolutionsList from '~/components/SolutionsList.vue'
import Stat from '~/components/Stat.vue'
import TextWithNumber from '~/components/RollingOffice/TextWithNumber.vue'
import TextBlock from '~/components/TextBlock.vue'
import TextFeature from '~/components/TextFeature.vue'
import TextMedia from '~/components/TextMedia.vue'
import TextMediaItem from '~/components/TextMediaItem.vue'
import TextSectioned from '~/components/TextSectioned.vue'
import TabLinkBar from '~/components/RollingOffice/TabLinkBar.vue'
import Tile from '~/components/RollingOffice/Tile.vue'
import TwoColumnText from '~/components/RollingOffice/TwoColumnText.vue'
import TextWithVideo from '~/components/RollingOffice/TextWithVideo.vue'
import TileItem from '~/components/RollingOffice/TileItem.vue'
import Timeline from '~/components/Timeline.vue'
import TimelineItem from '~/components/TimelineItem.vue'
import UserBookmarks from '~/components/UserBookmarks.vue'
import UserLogin from '~/components/UserLogin.vue'
import UserLogout from '~/components/UserLogout.vue'
import UserProfile from '~/components/UserProfile.vue'
import VideoClip from '~/components/VideoClip.vue'
import Modal from '~/components/Modal.vue'
import PdpVideo from '~/components/RollingOffice/PdpVideo.vue'
import MegamenuContent from '~/components/MegamenuContent.vue'
import MegamenuCard from '~/components/MegamenuCard.vue'

Vue.component('blok-page', Page)
Vue.component('blok-page-home', PageHome)
Vue.component('blok-page-configurator', PageConfigurator)
Vue.component('blok-page-404', Page404)
Vue.component('blok-page-error', PageError)
Vue.component('blok-page-rolling-office', PageRollingOffice)
Vue.component('blok-page-custom-services', PageCustomServices)

Vue.component('blok-cs-hero', CustomServicesHero)
Vue.component('blok-cs-text', CustomServicesText)
Vue.component('blok-cs-image-text', CustomServicesImageText)
Vue.component('blok-cs-image', CustomServicesImage)
Vue.component('blok-cs-text-on-image', CustomServicesTextOnImage)
Vue.component('blok-accordion', Accordion)
Vue.component('blok-accordion-item', AccordionItem)
Vue.component('blok-bio', Bio)
Vue.component('blok-bio-item', BioItem)
Vue.component('blok-two-column-text', TwoColumnText)
Vue.component('blok-blockquote', Blockquote)
Vue.component('blok-bookmark', Bookmark)
Vue.component('blok-breadcrumb', Breadcrumb)
Vue.component('blok-callout', Callout)
Vue.component('blok-cards', Cards)
Vue.component('blok-cards-item', CardsItem)
Vue.component('blok-carousel', Carousel)
Vue.component('blok-contact-button', ContactButton)
Vue.component('blok-contact-info', ContactInfo)
Vue.component('blok-course', Course)
Vue.component('blok-email-sign-up', EmailSignUp)
Vue.component('blok-form-contact', FormContact)
Vue.component('blok-form-create-account', FormCreateAccount)
Vue.component('blok-form-request', FormRequest)
Vue.component('blok-full-page-scroll', FullPageScroll)
Vue.component('blok-gallery', Gallery)
Vue.component('blok-gdpr', GDPR)
Vue.component('blok-header-contact-button', HeaderContactButton)
Vue.component('blok-hero', Hero)
Vue.component('blok-home', Home)
Vue.component('blok-image-feature', ImageFeature)
Vue.component('blok-image-grid', ImageGrid)
Vue.component('blok-instagram', Instagram)
Vue.component('blok-interactive-image', InteractiveImage)
Vue.component('blok-interactive-image-item', InteractiveImageItem)
Vue.component('blok-layout-aside', LayoutAside)
Vue.component('blok-list-actions', ListActions)
Vue.component('blok-list-actions-item', ListActionsItem)
Vue.component('blok-list-details', ListDetails)
Vue.component('blok-list-details-item', ListDetailsItem)
Vue.component('blok-list-icon', ListIcon)
Vue.component('blok-list-icon-item', ListIconItem)
Vue.component('blok-media', Media)
Vue.component('blok-media-and-text', MediaAndText)
Vue.component('blok-message-block', MessageBlock)
Vue.component('blok-page-header', PageHeader)
Vue.component('blok-page-header-account', PageHeaderAccount)
Vue.component('blok-picture', Picture)
Vue.component('blok-product-applications', ProductApplications)
Vue.component('blok-product-data', ProductData)
Vue.component('blok-product-details', ProductDetails)
Vue.component('blok-products-filter', ProductsFilter)
Vue.component('blok-products-list', ProductsList)
Vue.component('blok-reset-password', ResetPassword)
Vue.component('blok-section-intro', SectionIntro)
Vue.component('blok-slider', Slider)
Vue.component('blok-slider-item', SliderItem)
Vue.component('blok-social', Social)
Vue.component('blok-solution-data', SolutionData)
Vue.component('blok-solution-details', SolutionDetails)
Vue.component('blok-solution-details-product', SolutionDetailsProduct)
Vue.component('blok-solutions-filter', SolutionsFilter)
Vue.component('blok-solutions-list', SolutionsList)
Vue.component('blok-stat', Stat)
Vue.component('blok-tab-link-bar', TabLinkBar)
Vue.component('blok-text-with-number', TextWithNumber)
Vue.component('blok-text-block', TextBlock)
Vue.component('blok-text-feature', TextFeature)
Vue.component('blok-text-media', TextMedia)
Vue.component('blok-text-media-item', TextMediaItem)
Vue.component('blok-text-sectioned', TextSectioned)
Vue.component('blok-timeline', Timeline)
Vue.component('blok-tile', Tile)
Vue.component('blok-tile-item', TileItem)
Vue.component('blok-timeline-item', TimelineItem)
Vue.component('blok-user-bookmarks', UserBookmarks)
Vue.component('blok-user-profile', UserProfile)
Vue.component('blok-user-login', UserLogin)
Vue.component('blok-user-login', UserLogout)
Vue.component('blok-video-clip', VideoClip)
Vue.component('blok-text-with-image', TextWithImage)
Vue.component('blok-text-with-video', TextWithVideo)
Vue.component('blok-pdp-video', PdpVideo)
Vue.component('blok-megamenu-content', MegamenuContent)
Vue.component('blok-megamenu-card', MegamenuCard)
