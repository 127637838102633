//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['id'],
	methods: {
		saveBookmark() {
			console.log('SAVE bookmark! id:', this.id)

			this.$store.dispatch('bookmarks/addBookmark', { bookmark: this.id })
		},
		removeBookmark() {
			console.log('REMOVE bookmark! id:', this.id)

			this.$store.dispatch('bookmarks/removeBookmark', { bookmark: this.id })
		},
		showLoginModal() {
			// save intent object
			this.$intent.set('bookmark', this.$route.path, { id: this.id })

			this.$bus.$emit('openModal')
		}
	},
	mounted() {},
	computed: {
		loggedIn() {
			return this.$auth.loggedIn
		},
		isBookmarked() {
			return this.$store.getters['bookmarks/getBookmarkBySlug'](this.id)
		}
	}
}
