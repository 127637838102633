//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		Blockquote: () => import('@/components/Blockquote.vue'),
		TextMediaItem: () => import('@/components/TextMediaItem.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
