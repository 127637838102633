//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
        columnOneStyles() {
			let bgColor, textColor;
			if (this.blok && this.blok.column_1_bg) {
				bgColor = 'background:' + this.blok.column_1_bg.color + ';';
			}
			if (this.blok && this.blok.column_1_text_color) {
				textColor = 'color:' + this.blok.column_1_text_color + ';';
			}

			const styles = bgColor + textColor;

			return styles;
		},
        columnTwoStyles() {
			let bgColor, textColor;
			if (this.blok && this.blok.column_2_bg) {
				bgColor = 'background:' + this.blok.column_2_bg.color + ';';
			}
			if (this.blok && this.blok.column_2_text_color) {
				textColor = 'color:' + this.blok.column_2_text_color + ';';
			}

			const styles = bgColor + textColor;

			return styles;
		},
 
        isFullscreen() {
			if(this.blok && this.blok.is_fullscreen) {
				return ' fullscreen';
			}
		}
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		column1Text() {
			if (this.blok.column_1_text) {
				return this.$storyapi.richTextResolver.render(this.blok.column_1_text)
			}
		},
        column2Text() {
			if (this.blok.column_2_text) {
				return this.$storyapi.richTextResolver.render(this.blok.column_2_text)
			}
		}
	}
}
