//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	computed: {
		block1() {
			if (this.blok.block_1 && this.blok.block_1.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.block_1)
			}
		},
		block2() {
			if (this.blok.block_2 && this.blok.block_2.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.block_2)
			}
		},
		block3() {
			if (this.blok.block_3 && this.blok.block_3.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.block_3)
			}
		},
		block4() {
			if (this.blok.block_4 && this.blok.block_1.content[0].content) {
				return this.$storyapi.richTextResolver.render(this.blok.block_4)
			}
		}
	},
	methods: {},
	mounted() {}
}
