/* This file from Storyblok */
export const state = () => ({
	cacheVersion: ''
})

export const mutations = {
	setCacheVersion(state, version) {
		state.cacheVersion = version
	}
}

export const actions = {
	async nuxtServerInit({ dispatch }) {
		//await dispatch('core/load')
		await dispatch('companyTypes/fetchAllCompanyTypes')
		await dispatch('gdpr/fetchAllGDPRData')
		await dispatch('globalFooter/fetchAllFooterData')
		await dispatch('globalHeader/fetchAllNavData')
		await dispatch('products/fetchAllProducts')
		await dispatch('products/fetchProductApplications')
		await dispatch('solutions/fetchSolutionCategories')
		await dispatch('solutions/fetchAllSolutions')
	},

	loadCacheVersion({ commit }) {
		return this.$storyapi.get(`cdn/spaces/me`).then(res => {
			commit('setCacheVersion', res.data.space.version)
		})
	}
}
