//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
export default {
	components: {},
	data() {
		return {
			selectedColor:''
		}
	},
	props: ['blok'],
	methods: {
		changeSelectedColor(event) {
			if (event) { 
				this.selectedColor = event.target.dataset.color;
				$(event.target).closest('.interactive-block').removeAttr('style');
			}
		},
		isSelectedColor(color){
			return color === this.selectedColor;
		},
		hasAtLeastOneItem(){
			return this.blok && this.blok.items.length > 0;
		},
		hasItems(){
			return this.blok && this.blok.items.length > 1;
		},
		toggle(event) {
			if (event) {
			
			}
		},

		backgroundStyle() {
			if(this.blok && this.blok.background) {
				return 'background-image:url(' + this.blok.background.filename + ')';
			}
		}
	},
	mounted() {
		if(this.hasAtLeastOneItem() && this.blok.preselect){
			this.selectedColor = this.blok.items[0].color_label;
		}

	},
	computed: {
		text() {
			if (this.blok.text) {
				return this.$storyapi.richTextResolver.render(this.blok.text).replace(/\|/g, "<br />");
			}
		}

	}
}
