//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		TextSectioned: () => import('~/components/TextSectioned.vue')
	},
	data() {
		return {
			aria: true,
			showContent: false
		}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {}
}
