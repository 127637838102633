//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {
			aria: true,
			hasErrors: false,
			errorServer: false,
			errorMessages: {},
			successMessage: false,
			password: '',
			password_confirmation: '',
			email: '',
			token: ''
		}
	},

	props: ['blok', 'user'],
	computed: {
	},
	created() {

		this.email = this.$route.query.email
		this.token = this.$route.query.token

		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},

	methods: {
		invalidateForm() {
			this.hasErrors = true
		},
		errors(fieldId) {
			// return formatted error messages for a given field

			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			//if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		handlePasswordChange() {

			// check for any additional frontend validation
			if (this.password != this.password_confirmation) {
				this.errorMessages = this.errorMessages || {}
				this.errorMessages['password_confirmation'] = ['Passwords must match.']

				return
			}

			// make the password change request
			this.$axios.post(process.env.doPasswordChangeEndpoint, {
				token: this.token,
				email: this.email,
				password: this.password,
				password_confirmation: this.password_confirmation
			}).then(response => {

				this.successMessage = true

			}).catch(error => {
				if (error.response && error.response.data && error.response.data.errors) {
					let k;
					for(k in error.response.data.errors) {
						this.errorMessages[k] = error.response.data.errors[k]
					}
				}
				this.invalidateForm()
			})

		}

	}
}
