//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data() {
		return {
			errors: false,
			serverError: false,
			email: '',
			industry: '',
			success: false
		}
	},
	props: ['blok', 'title', 'description'],
	computed: {
		formAction() {
			return process.env.subscribeFormAction
		}
	},
	created() {
		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},
	methods: {
		invalidateForm() {
			this.errors = true
		},
		submit() {
			this.$axios
				.post(
					this.formAction,
					{
						email: this.email,
						industry: this.industry
					},
					{
						debug: false
					}
				)
				.then(data => {
					if (data.status == 201) {
						this.success = true
						this.errors = false
						this.serverError = false
						this.email = ''
						setTimeout(() => (this.success = false), 7500)
					}
				})
				.catch(error => {
					// @todo handle error response
					this.serverError = true
				})
		}
	},
	mounted() {}
}
