//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		is3Equal(){return this.blok.style === '3_equal'},
		is2Equal(){return this.blok.style === '2_equal'},
		isBiggerLeft(){return this.blok.style === 'bigger_left'},
		isBiggerRight(){return this.blok.style === 'bigger_right'},
		isCarousel(){return this.blok.style === 'carousel'},
		styles() {
			let bgColor, textColor;
			if (this.blok && this.blok.background) {
				bgColor = 'background:' + this.blok.background.color + ';';
			}
			if (this.blok && this.blok.text_color) {
				textColor = 'color:' + this.blok.text_color + ';';
			}

			const styles = bgColor + textColor;

			return styles;
		},
		isFullscreen() {
			if(this.blok && this.blok.is_fullscreen) {
				return ' fullscreen';
			}
		}
	},
	mounted() {
		console.debug(this.blok)
		console.debug(this.blok.style)
	},
	computed: {
	}
}
