//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components: {
    },
    data() {
        return {}
    },
    props: ['blok'],
    methods: {
        bgimage() {
            if (this.blok.image) {
                return 'background-image:url(' + this.blok.image.filename + ')';
            }
        }
        
    },
    mounted() {
        console.debug(this.blok);
    },
    computed: {
        description() {
            if (this.blok.description) {
                return this.$storyapi.richTextResolver.render(this.blok.description)
            }
        },
        note() {
            if (this.blok.note) {
                return this.$storyapi.richTextResolver.render(this.blok.note)
            }
        }
    }
}
