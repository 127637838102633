//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: ['blok'],
	methods: {
		loadMedia: function() {
			if (!this.video.hasAttribute('src')) {
				this.video.setAttribute('src', this.videoSrc)
			}
		}
	},
	mounted() {
		this.videoClip = this.$refs.videoClip
		this.video = this.$refs.video
		this.videoSrc = this.$refs.videoSrc.getAttribute('data-src')

		if (this.$motionPref == true) {
			let observer = new IntersectionObserver(entries => {
				entries.forEach(entry => {
					if (entry['isIntersecting'] == true) {
						this.loadMedia()
						this.video.play()
					} else {
						this.video.pause()
					}
				})
			})
			observer.observe(this.videoClip)
		}

		this.$bus.$on('stopVideoClip', () => {
			if (this.video) {
				this.video.pause()
			}
		})

		this.$bus.$on('playVideoClip', () => {
			if (this.video) {
				this.video.play()
			}
		})
	}
}
