//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {
			gdprVisible: false
		}
	},
	props: ['blok'],
	computed: {
		gdprData() {
			return this.$store.getters['gdpr/getGDPRData']
		},
		text() {
			if(!this.gdprData.story){
				return '';
			}
			return this.$storyapi.richTextResolver.render(this.gdprData.story.content.gdpr[0].text)
		}
	},
	methods: {
		checkGDPR() {
			this.gdpr = this.$refs.gdpr
			if (process.client) {
				const gdprStorage = localStorage.getItem('gdpr')

				if (gdprStorage !== 'agreed') {
					// Show GDPR Message
					this.gdprVisible = true
				}
			}
		},
		setGDPR() {
			if (process.client) {
				// Store Acceptance
				localStorage.setItem('gdpr', 'agreed')

				// Hide GDPR Message
				this.gdprVisible = false
			}
		}
	},
	mounted() {
		this.$store.dispatch('gdpr/fetchAllGDPRData');
		this.checkGDPR()
	}
}
