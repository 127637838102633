export const state = () => ({
	gdprData: []
})

export const actions = {
	async fetchAllGDPRData({ state, commit }) {
		let gdprData = await this.$axios.$get('https://api.storyblok.com/v1/cdn/stories/gdpr?token=' + process.env.storyBlokAPIKey + '&version=' + process.env.storyBlokAPIVersion, { useCache: false })
		commit('setGDPRData', gdprData)
	}
}

export const getters = {
	getGDPRData: state => {
		return state.gdprData
	}
}

export const mutations = {
	setGDPRData(state, gdprData) {
		state.gdprData = gdprData
	}
}
