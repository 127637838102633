//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
        addGridItems() { return this.blok.add_grid_items },
        threeColumns(){ return this.blok.grid_columns === 'three' },
        fourColumns(){ return this.blok.grid_columns === 'four' },
        getGridCards() {
            if (this.blok && this.blok.grid_card) {
                return this.blok.grid_card;
            }
            return {};
        },
        justDescription() { return this.blok.just_description },

        hasOnlyDescription() {
            if( this.blok && this.blok.just_description ) {
                return ' description-only ';
            }
        }
	},
	mounted() {
		console.debug(this.blok);
	},
	computed: {
		description() {
			if (this.blok && this.blok.description) {
				return this.$storyapi.richTextResolver.render(this.blok.description).replace(/\|/g, "<br />");
			}
		}
	}
}
