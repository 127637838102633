import Vue from 'vue'

export default ({app}, inject) => {
	const storageKey = 'intent'

	inject('intent', {
		set(type, page, data) {
			// create intent object
			const intent = {
				type: type,
				page: page,
				data: data
			}

			console.log("SET INTENT");
			console.log(intent);
			// save intent in localstorage
			window.localStorage.setItem(storageKey, JSON.stringify(intent))
		},
		exists() {
			const intent = JSON.parse(window.localStorage.getItem(storageKey))
			return intent ? true : false
		},
		handle() {
			// get intent from localstorage
			const intent = JSON.parse(window.localStorage.getItem(storageKey))
			if (!intent) return

			console.log("HANDLE INTENT");
			console.log(intent);
			// do the actions for the intent
			switch(intent.type) {
				case 'bookmark':
					// go to page
					app.router.push(intent.page)
					// save bookmark
					app.store.dispatch('bookmarks/addBookmark', { bookmark: intent.data.id })
					break
				case 'download':
					// go to page
					app.router.push(intent.page)
					// download file
					const anchor = document.createElement('a')
					anchor.setAttribute('href', intent.data.file)
					anchor.setAttribute('target', '_blank')
					anchor.click()
					break
				default:
					break
			}
		},
		clear() {
			// clear any existing intent
			window.localStorage.removeItem(storageKey)		
		}
	})
}
