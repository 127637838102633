//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		VideoClip: () => import('@/components/VideoClip.vue')
	},
	data() {
		return {
			gsapReady: false,
			mainTimeline: null,
			mainScrollTrigger: null,
			slides: null,
			slideCount: 0
		}
	},
	mixins: [],
	props: ['blok'],
	computed: {
		isNoTouch() {
			return document.documentElement.classList.contains('no-touch')
		}
	},
	methods: {
		image(image, fileType = null, isHighResolution = false) {
			const highResolutionScale = 2
			const notHighResolutionScale = 1.2;
			let width, height

			if (image && image.filename) {
				// default size
				width = 1440
				height = 0

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				} else {
					height *= notHighResolutionScale
					width *= notHighResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(image.filename, {
					size: width + 'x' + height,
					focus: image.focus,
					fileType: fileType
				})
				return processedImage
			}
		},
		initHome() {
			if (this.$gsap && this.$ScrollTrigger) {
				// this.initMainTimeline()
				// this.initFocusedAnchors()
				// this.initScroll()
				this.initWordTimelines()
				this.gsapReady = true
			}
				
		},
		initMainTimeline() {
			const gsap = this.$gsap
			const ScrollTrigger = this.$ScrollTrigger

			// main timeline for slides
			this.mainTimeline = gsap.timeline({
				paused: true
			})

			// build out the timeline

			// start label

			for (let i = 0; i < this.slideCount; i++) {
				if (i < this.slideCount - 1) {
					// leaving
					this.mainTimeline
						.to(
							textArray[i],
							{
								y: '-25%',
								autoAlpha: 0,
								transform: 'scale(0.5)',
								display: 'none',
								duration: 1,
								ease: 'none'
							},
							i
						)
						.addLabel('slidelabel' + i)
					this.mainTimeline.to(mediaArray[i], { autoAlpha: 0, display: 'none', duration: 1, ease: 'power2.in' }, i)
				}

				// entering
				if (textArray[i + 1]) this.mainTimeline.to(textArray[i + 1], { y: '0%', autoAlpha: 1, transform: 'scale(1)', display: 'flex', duration: 1, ease: 'none' }, i)
				if (mediaArray[i + 1]) this.mainTimeline.to(mediaArray[i + 1], { autoAlpha: 1, display: 'flex', duration: 1, ease: 'power2.in' }, i)
			}

			// use scrolltrigger for touch devices, for better performance than scroll event
			// if (!this.isNoTouch) {
			this.mainScrollTrigger = ScrollTrigger.create({
				animation: this.mainTimeline,
				trigger: '.home-triggers',
				start: 'top top',
				end: 'bottom bottom',
				scrub: true,
				snap: {
					snapTo: 'labels',
					duration: { min: 0.5, max: 1 }
				}
			})
		},
		initWordTimelines() {
			const gsap = this.$gsap
			const ScrollTrigger = this.$ScrollTrigger

			// timelines for orange word animations
			const secsPerWord = 1.5

			// this.slides.forEach(slide => {
				const wordContainer = document.querySelector('.words')
				if (!wordContainer) return

				const words = wordContainer.querySelectorAll('span')
				if (!words) return

				const wordTimeline = gsap.timeline({
					paused: false,
					repeat: -1,
					delay: 0.5
				})

				for (let i = 0; i < words.length; i++) {
					// leaving word
					wordTimeline.to(words[i], { autoAlpha: 0, duration: 0.25 }, (i + 1) * secsPerWord - 0.5)

					// entering word
					if (i < words.length - 1) {
						wordTimeline.to(
							words[i + 1],
							{
								startAt: {
									y: '100px'
								},
								y: '0px',
								autoAlpha: 1,
								duration: 0.5
							},
							(i + 1) * secsPerWord - 0.5
						)
					}
				}

				// enter first word again to complete loop
				wordTimeline.to(
					words[0],
					{
						startAt: {
							y: '100px'
						},
						y: '0px',
						autoAlpha: 1,
						duration: 0.5
					},
					words.length * secsPerWord - 0.5
				)

				// set scroll triggers to start/stop word animations
				ScrollTrigger.create({
					animation: wordTimeline,
					trigger: `.home-slider`,
					start: 'top top',
					toggleActions: 'play pause resume pause'
				})
			// })
		},
		wordArray(words) {
			if (!words) return

			return words.split(/\r?\n/)
		},
		isVideoLink(link) {
			if (link.linktype === 'url' && link.cached_url) {
				const urlObject = new URL(link.cached_url)
				if (urlObject.hostname.match('vimeo') || urlObject.hostname.match('youtube')) return true
			}

			return false
		},
		showVideoModal: function(url) {
			this.$bus.$emit('openVideoModal', { type: 'video', url: url })
		}
	},
	mounted() {
		let para = document.createElement('script');
      	para.setAttribute('src', 'https://cdn.jsdelivr.net/npm/simple-parallax-js@5.5.1/dist/simpleParallax.min.js');
      	document.head.appendChild(para);
		this.initHome();

		this.$bus.$emit('transparentHeader');
		para.onload = function() {
			var image = document.querySelectorAll('img.slideimage');
			new simpleParallax(image, {delay: .1, transition: 'cubic-bezier(.49,.73,.64,.66)', scale: 1.3});
		};
		
	},
	beforeDestroy() {
		if (this.mainTimeline) this.mainTimeline.kill()
		if (this.mainScrollTrigger) this.mainScrollTrigger.kill()
	}
}
