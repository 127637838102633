//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    components: {
    },
    data() {
        return {}
    },
    props: ['blok'],
    methods: {
        background() {
            if (this.blok.background_image.filename) {
                return 'background: url('+ this.blok.background_image.filename +') center center no-repeat ' + this.blok.background.color;
            } else {
                return 'background:' + this.blok.background.color;
            }
        },
        textColor() {
            return 'color: ' + this.blok.text_color;
        }
        
    },
    mounted() {
        console.debug(this.blok);
    },
    computed: {
        title() {
            if (this.blok.title) {
                return this.$storyapi.richTextResolver.render(this.blok.title).replace(/\|/g, "<br />")
            }
        },
        description() {
            if (this.blok.description) {
                return this.$storyapi.richTextResolver.render(this.blok.description)
            }
        },
    }
}
