import { render, staticRenderFns } from "./TextWithImage.vue?vue&type=template&id=77aafe6c&"
import script from "./TextWithImage.vue?vue&type=script&lang=js&"
export * from "./TextWithImage.vue?vue&type=script&lang=js&"
import style0 from "./TextWithImage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/home/webadmin/sites/bnind.com/bnfe/releases/20231207T215914/components/Picture.vue').default})
