//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
	},
	data() {
		return {
			tabs: [
				{ label: 'Features', link: '/products/gira/' },
				{ label: 'Reconfigure', link: '/products/gira/reconfigure/' },
				{ label: 'Design Resources', link: '/products/gira/design-resources/' }
			],
			selected: ''
		}
	},
	props: ['blok'],
	methods: {
		isSelected(url){
			if(url === this.$route.path || url + '/' === this.$route.path){
				return true;
			}
			return false;
		}
	},
	mounted() {
		// console.log(this.$route);
		// console.log(this.blok);
	},
	computed: {}
}
