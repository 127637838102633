//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {
			aria: true,
			togglePassword: false,
			hasErrors: false,
			errorServer: false,
			errorMessages: null,
			successMessage: false,
			password: '',
			password_confirmation: '',
			profile: {
				email: this.$auth.user.email,
				firstname: this.$auth.user.firstname,
				lastname: this.$auth.user.lastname,
				title: this.$auth.user.title,
				phone: this.$auth.user.phone,
				company: this.$auth.user.company,
				companytype: this.$auth.user.companytype,
				city: this.$auth.user.city,
				state: this.$auth.user.state,
				country: this.$auth.user.country,
				industry: this.$auth.user.industry,
				email_optin: this.$auth.user.email_optin
			}
		}
	},

	props: ['blok', 'user'],
	computed: {
		companyTypesData() {
			return this.$store.getters['companyTypes/getCompanyTypes']
		}
	},
	created() {
		console.log('user is logged in?', this.$auth.loggedIn)

		// needed before any form requests
		this.$axios
			.get(process.env.csrfCookieAction)
			.then(response => {
			})
			.catch(error => {
				console.error('unable to fetch csrf cookie', error)
			})
	},

	methods: {
		invalidateForm() {
			this.hasErrors = true
		},
		errors(fieldId) {
			// return formatted error messages for a given field

			// if no field id or there are no error messages, leave
			if (!fieldId || !this.errorMessages) return

			// if field doesn't actually exist, leave
			if (!document.getElementById(fieldId)) return

			const messages = []

			// if errors exist for this field, push them to an array
			if (this.errorMessages[fieldId]) {
				this.errorMessages[fieldId].forEach(message => {
					messages.push(message)
				})
			}

			// if messages exist, display them
			if (messages) return messages.join('<br>')
		},
		handleProfileUpdate() {
			// clear existing errors first
			this.errorMessages = null

			this.handlePasswordChange()

			// if there are frontend errors, don't submit the form
			if (this.errorMessages !== null) return

			this.$axios
				.put(process.env.profileUpdateEndpoint, this.profile)
				.then(response => {
					// show success (toast?)
					this.$auth.fetchUser()

					this.successMessage = true

					setTimeout(() => (this.successMessage = false), 7500)
				})
				.catch(error => {
					// do nothing if there is no error object
					if (!error) return

					// use server-side validation messages if they exist
					// otherwise use a generic error message
					if (error.response && error.response.data && error.response.data.errors) {
						this.errorMessages = error.response.data.errors
					} else {
						this.errorServer = true
					}
				})
		},
		handlePasswordChange() {
			if (!this.togglePassword) {
				console.info('not changing password')
				return
			}

			// check for any additional frontend validation
			if (this.password != this.password_confirmation) {
				this.errorMessages = this.errorMessages || {}
				this.errorMessages['password_confirmation'] = ['Passwords must match.']

				return
			}

			// make the password change request
			this.$axios
				.put(process.env.passwordChangeEndpoint, {
					password: this.password,
					password_confirmation: this.password_confirmation
				})
				.then(response => {
					// @todo push a success message somewhere?
					this.togglePassword = false
					this.password = ''
					this.password_confirmation = ''
				})
				.catch(error => {
					this.errorMessages = this.errorMessages || {}
					if (error.response && error.response.data && error.response.data.errors) {
						let k;
						for(k in error.response.data.errors) {
							this.errorMessages[k] = error.response.data.errors[k]
						}
					}
					this.invalidateForm()
				})
		},
		handleLogout() {
			this.$auth.logout()
		}
	}
}
