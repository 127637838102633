//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		Picture: () => import('~/components/Picture.vue'),
		VideoClip: () => import('@/components/VideoClip.vue')
	},
	data() {
		return {
			showVideo: false
		}
	},
	mixins: [],
	props: ['blok'],
	computed: {},
	methods: {
		showVideoModal: function() {
			this.$bus.$emit('openVideoModal', { type: 'video', url: this.blok.video_link.cached_url })
		}
	},
	mounted() {}
}
