//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data() {
		return {
			instagramItems: []
		}
	},
	props: ['blok'],
	methods: {
		async loadInstagramData() {
			const items = await this.$axios.get(process.env.instagramEndpoint)
			if (items.data && items.data.length)
				this.instagramItems = items.data.slice(0, 6);
		},
		imageSource(item) {
			if (!item) return

			let source

			// iterate through thumbnails for the best size image to use
			for (const index in item.thumbnails) {
				const thumb = item.thumbnails[index]
				if (parseInt(thumb.config_width) >= 428) {
					source = thumb.src
					break
				}
			}

			if (source)
				return source
		}
	},
	created() {
		this.loadInstagramData()
	},
	mounted() {},
	computed: {}
}
