//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {
		Bookmark: () => import('~/components/Bookmark.vue')
	},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		image(fileType = null, isHighResolution = false) {
			const highResolutionScale = 1
			let width, height

			if (this.blok && this.blok.image && this.blok.image.filename) {
				// default size
				width = 750
				height = 793

				// scale up for highres image
				if (isHighResolution) {
					height *= highResolutionScale
					width *= highResolutionScale
				}

				// crop/scale the image
				const processedImage = this.$func.imageService(this.blok.image.filename, {
					size: width + 'x' + height,
					focus: this.blok.image.focus,
					fileType: fileType
				})
				// return processedImage
				return this.blok.image.filename
			}
		},
		styles() {
			let bgColor, textColor;
			if (this.blok && this.blok.background) {
				bgColor = 'background:' + this.blok.background.color + ';';
			}
			if (this.blok && this.blok.text_color) {
				textColor = 'color:' + this.blok.text_color + ';';
			}

			const styles = bgColor + textColor;

			return styles;
		},
		arrow() {
			if(this.blok && this.blok.text_color) {
				return '/img/ui/arrow-right-' + this.blok.text_color + '.svg';
			} else {
				return '/img/ui/arrow-right-black.svg';
			}
		},

		isFullscreen() {
			if(this.blok && this.blok.is_fullscreen) {
				return ' fullscreen';
			}
		}
	},
	mounted() {
		console.debug(this.blok)
	},
	computed: {
		text() {
			if (this.blok.text) {
				return this.$storyapi.richTextResolver.render(this.blok.text)
			}
		},

		subtitle() {
			if (this.blok.subtitle) {
				return this.$storyapi.richTextResolver.render(this.blok.subtitle)
			}
		},

		bookmarkId() {
			return this.$route.params.pathMatch
		}
	}
}
