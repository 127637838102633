//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	components: {},
	data() {
		return {}
	},
	props: ['blok'],
	methods: {
		filterChange() {
			this.activeFilters()
		},
		activeFilters() {
			const active = []
			this.$refs.filters.querySelectorAll('input[type=checkbox]:checked').forEach(checkbox => {
				let productId = checkbox.getAttribute('data-id')
				active.push(productId)
			})

			// emit filter array so cards can update
			this.$bus.$emit('productsFilterApplication', active)
		}
	},
	mounted() {},
	computed: {
		filters() {
			let products = this.$store.getters['products/getAllProducts']
			const validFilters = []

			for (let i = 0; i < products.length; i++) {
				// body components
				let data = products[i].content.data

				if (data) {
					for (let j = 0; j < data.length; j++) {
						if (data[j].component === 'product_data') {
							const applications = data[j].applications
							for (let k = 0; k < applications.length; k++) {
								const application = this.$store.getters['products/getProductApplicationByValue'](applications[k])
								if (application && !validFilters.includes(application)) validFilters.push(application)
							}
						}
					}
				}
			}

			if (validFilters.length) {
				// make sure filters match the order in the SB datasource
				validFilters.sort((a, b) => parseInt(a.index) - parseInt(b.index))

				return validFilters
			}
		}
	}
}
