//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	data() {
		return {}
	},
	props: ['blok'],
	methods: {},
	mounted() {},
	computed: {
		text() {
			if (this.blok.text) {
				return this.$storyapi.richTextResolver.render(this.blok.text)
			}
		},
		text2() {
			if (this.blok.text_2) {
				return this.$storyapi.richTextResolver.render(this.blok.text_2).replace(/\|/g, "<br />");
			}
		}
	}
}
